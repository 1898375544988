.AgeQuestionbody {
  display: flex;
  justify-content: space-around;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

.AgeQuestionbody .AgeQuestioninnerbody {
  border-radius: 17px;
  padding: 40px 100px 33px 100px;
  max-width: 600px;
  background-color: rgba(0, 0, 0, 0.9);
  min-height: 40px;
  height: 100%;
  width: 100%;
}

/* -------------fixed back popup--------------- */

.forFixedBack {
  border-radius: 17px;
  padding: 40px 100px 33px 100px;
  max-width: 600px;
  background-color: rgba(0, 0, 0, 0.9);
  min-height: 40px;
  width: 100%;
  position: fixed;
}

.AgeQuestionbody .forFixedBack .AgeQuestiontitlbody .titles h2 {
  text-align: center;
  font: normal normal 500 24px/40px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: -18px;
}

.AgeQuestionbody .forFixedBack .AgeQuestiontitlbody {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.AgeQuestionbody .forFixedBack .AgeQuestiontitlbody .titles {
  width: 100%;
}

/* ---------------------------------------------------------------- */

.AgeQuestionbody .AgeQuestioninnerbody .AgeQuestiontitlbody {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.AgeQuestionbody .AgeQuestioninnerbody .AgeQuestiontitlbody .titles {
  width: 100%;
}

.AgeQuestionbody .AgeQuestioninnerbody .AgeQuestiontitlbody .titles h2 {
  text-align: center;
  font: normal normal 500 24px/40px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: -18px;
}

.Featurgoalsubtitlesage {
  padding-top: 5px;
}



.containerlevel {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.containerlevel input[type="radio"] {
  margin-top: 29px;
  opacity: 0;
}

.geekmarks {
  margin-top: -6px;
  position: absolute;
  max-width: 424px;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #5c5858 !important;
  border: 1px solid !important;
}

.inputfieldfirsts:checked~.geekmarks {
  border: 2px solid #29aae3 !important;
}

.ageQuestionbutton {
  flex-wrap: wrap;
  padding-top: 101px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ageQuestionbutton>div {
  margin: 5px;
}

.ageQuestionbutton .ageQuestionsubmitbutton button {
  background: #29aae3 0% 0% no-repeat padding-box;
  height: 45px;
  min-width: 129px;
  font: normal normal 600 14px/22px Rubik;
  color: rgba(0, 0, 0, 0.9);
  border-radius: 5px;
}

.ageQuestionbutton .ageQuestionskipbutton button {
  height: 40px;
  width: 100px;
  background-color: transparent !important;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

::placeholder {
  font-size: 13px !important;
  font-weight: 400;
  color: rgb(212, 204, 204);
  opacity: 1;
  /* Firefox */
}

/* experience css */

.ExperienceQuestionbody {
  display: flex;
  justify-content: space-around;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

.ExperienceQuestionbody .ExperienceQuestioninnerbody {
  border-radius: 17px;
  padding: 50px 100px 33px 100px;
  max-width: 600px;
  background-color: rgba(0, 0, 0, 0.9);
  min-height: 40px;
  height: 100%;
  width: 100%;
}

.ExperienceQuestionbody .ExperienceQuestioninnerbody .ExperienceQuestiontitlbody {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ExperienceQuestionbody .ExperienceQuestioninnerbody .ExperienceQuestiontitlbody .titleexperience {
  width: 100%;
}

.ExperienceQuestionbody .ExperienceQuestioninnerbody .ExperienceQuestiontitlbody .titleexperience h2 {
  text-align: center;
  font: normal normal 500 24px/33px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.Featurgoalexperiense {
  padding-top: 13px;
}

.Featurgoalexperiense>div {
  padding-top: 40px;
}

.containerlevelexperiense {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.containerlevelexperiense input[type="radio"] {
  margin-top: 29px;
  opacity: 0;
}

.geekmarks {
  margin-top: -6px;
  position: absolute;
  max-width: 424px;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #5c5858 !important;
}

.inputfieldexperinse:checked~.geekmarks {
  border: 2px solid #29aae3 !important;
}

.ExperienceQuestionbutton {
  flex-wrap: wrap;
  padding-top: 68px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .ExperienceQuestionbutton>div {
  margin: 15px;
} */

.ExperienceQuestionbutton .ageQuestionsubmitbutton button {
  background-color: transparent !important;
  height: 45px;
  width: 119px;
  border-radius: 5px;
  border: 1px solid #707070;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.ExperienceQuestionbutton .ageQuestionskipbutton button {
  height: 45px;
  width: 129px;
  border-radius: 5px;
  background: #29aae3 0% 0% no-repeat padding-box;
  font: normal normal 600 14px/22px Rubik;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* Age Question css */


.GenderQuestionbody {
  display: flex;
  justify-content: space-around;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
}



.GenderQuestionbody .GenderQuestioninnerbody {
  border-radius: 17px;
  padding: 40px 100px 33px 100px;
  max-width: 600px;
  background-color: rgba(0, 0, 0, 0.9);
  min-height: 40px;
  height: 100%;
  width: 100%;
}

.GenderQuestionbody .GenderQuestioninnerbody .GenderQuestiontitlbody {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.GenderQuestionbody .GenderQuestioninnerbody .GenderQuestiontitlbody .titlegender {
  width: 100%;
}

.GenderQuestionbody .GenderQuestioninnerbody .GenderQuestiontitlbody .titlegender h2 {
  text-align: center;
  font: normal normal 500 24px/40px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: -18px;
}

.Featurgoalsubtitlesgender {
  padding-top: 5px;
}

.Featurgoalsubtitlesgender>div {
  padding-top: 40px;
}

.genderlabel {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.genderlabel input[type="radio"] {
  margin-top: 29px;
  opacity: 0;
}

/* .titleClose svg {
  top: -14px;
  left: -12px;
  cursor: pointer;
}

.titleClose {
  position: relative;
} */

.geekmarks {
  margin-top: -6px;
  position: absolute;
  max-width: 424px;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #5c5858 !important;
}

.inputfieldgender:checked~.geekmarks {
  border: 2px solid #29aae3 !important;
}

.GenderQuestionbutton {
  flex-wrap: wrap;
  padding-top: 100px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .ExperienceQuestionbutton>div {
  margin: 30px;
} */

.GenderQuestionbutton .GenderQuestionPreviousbutton button {
  background-color: transparent !important;
  border: 1px solid gray;
  height: 45px;
  width: 119px;
  border-radius: 5px;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.GenderQuestionbutton .GenderQuestionNextbutton button {
  height: 45px;
  width: 129px;
  background-color: #29aae3 !important;
  border-radius: 5px;
  font: normal normal 600 14px/22px Rubik;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.GenderQuestionbutton .Skipsurveybutton button {
  height: 40px;
  width: 100px;
  background-color: transparent !important;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

/* Language Question css */

.LanguageQuestionbody {
  display: flex;
  justify-content: space-around;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

.LanguageQuestionbody .LanguageQuestioninnerbody {
  border-radius: 17px;
  padding: 40px 100px 33px 100px;
  max-width: 600px;
  background-color: rgba(0, 0, 0, 0.9);
  min-height: 40px;
  height: 100%;
  width: 100%;
}

.LanguageQuestionbody .LanguageQuestioninnerbody .LanguageQuestiontitlbody {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.LanguageQuestionbody .LanguageQuestioninnerbody .LanguageQuestiontitlbody .titlelangauage {
  width: 100%;
}

.LanguageQuestionbody .LanguageQuestioninnerbody .LanguageQuestiontitlbody .titlelangauage h2 {
  text-align: center;
  font: normal normal 500 24px/33px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.Featurgoalsubtitleslanguage {
  padding-top: 5px;
}

.Featurgoalsubtitleslanguage>div {
  padding-top: 40px;
}

.languagelevel {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.languagelevel input[type="radio"] {
  margin-top: 29px;
  opacity: 0;
}

.geekmarks {
  margin-top: -6px;
  position: absolute;
  max-width: 424px;
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #5c5858 !important;
  right: 0px;
  left: 0px;
  margin: auto;
}

.inputfieldlanguage:checked~.geekmarks {
  border: 2px solid #29aae3 !important;
}

.LanguageQuestionbutton {
  flex-wrap: wrap;
  padding-top: 45px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LanguageQuestionbutton>div {
  margin: 10px;
}

.LanguageQuestionbutton .LanguageQuestionPreviousbutton button {
  background-color: transparent !important;
  height: 45px;
  width: 119px;
  border-radius: 5px;
  border: 1px solid #707070;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.LanguageQuestionbutton .LanguageQuestionNextbutton button {
  height: 45px;
  width: 129px;
  border-radius: 5px;
  background: #29aae3 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  font: normal normal 600 14px/22px Rubik;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.LanguageQuestionbutton .Skipsurveybutton button {
  height: 40px;
  width: 100px;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

@media only screen and (min-width: 320px) and (max-width: 555px) {
  /* .AgeQuestionbody {
    margin-top: 50px !important;
  }
 

  .ExperienceQuestionbody {
    margin-top: 50px !important;
  }
  .LanguageQuestionbody {
    margin-top: 50px !important;
  }

  .GenderQuestionbody {
    margin-top: 50px !important;
  } */

  .AgeQuestionbody .AgeQuestioninnerbody {
    border-radius: 17px;
    padding: 75px 10px 33px 10px;
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.9);
    min-height: 40px;
    height: 100%;
    width: 100%;
  }

  .ExperienceQuestionbody .ExperienceQuestioninnerbody {
    border-radius: 17px;
    padding: 75px 10px 33px 10px;
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.9);
    min-height: 40px;
    height: 100%;
    width: 100%;
  }

  .LanguageQuestionbody .LanguageQuestioninnerbody {
    border-radius: 17px;
    padding: 75px 10px 33px 10px;
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.9);
    min-height: 40px;
    height: 100%;
    width: 100%;
  }

  .GenderQuestionbody .GenderQuestioninnerbody {
    border-radius: 17px;
    padding: 75px 10px 33px 10px !important;
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.9);
    min-height: 40px;
    height: 100%;
    width: 100%;
  }
}

/* @media only screen and (min-width: 320px) and (max-width: 350px) {
  .AgeQuestionbody {
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
  .ExperienceQuestionbody {
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
} */

/* @media only screen and (min-width: 320px) and (max-width: 601px) {
  .GenderQuestionbody {
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 604px) {
  .LanguageQuestionbody {
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
} */
/* @media only screen and (min-width: 556px) and (max-width: 556px) {
  .LanguageQuestionbody {
    margin-top: 100px !important;
    margin-bottom: 30px !important;
  }
} */

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .GenderQuestionbutton {
    display: block !important;
    text-align: center !important;
  }

  .GenderQuestionbutton .GenderQuestionNextbutton button {
    margin-top: 10px !important;
    text-align: center !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .LanguageQuestionbutton {
    display: block !important;
    text-align: center !important;
  }

  .LanguageQuestionbutton .LanguageQuestionNextbutton button {
    margin-top: 10px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .ExperienceQuestionbutton {
    text-align: center !important;
    display: block !important;
  }

  .ExperienceQuestionbutton .ageQuestionskipbutton button {
    margin-top: 10px !important;
  }
}

.Questioninputtext {
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 10px;
}

.numbering {
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
}

.breath_title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.breath_inner_body {
  max-width: 604px;
  background: rgba(0, 0, 0, 0.9) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.breath_title_body {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.breath_title_body .breath_first_title h4 {
  text-align: left;
  font: normal normal 600 24px/53px Rubik;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}


/* .about_first_title{
  margin-top: 42px;

} */
.about_first_title p {
  text-align: center;
  font: normal normal 300 13px/18px Rubik;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.about_first_title {
  margin-top: 11px;
}

.first_title_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 38px;
}

.first_title_button>button {
  margin: 9px;
}

.first_title_button .button1 {
  max-width: 129px;
  min-height: 45px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #29AAE3;
  opacity: 1;
  width: 100%;
  height: 100%;
  font: normal normal 600 14px/22px Rubik;
  letter-spacing: 0px;
  color: #000000;
  border-radius: 5px;
}

.first_title_button .button2 {
  max-width: 129px;
  min-height: 45px;
  background: #29AAE3 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  height: 100%;
  font: normal normal 600 14px/22px Rubik;
  letter-spacing: 0px;
  color: #000000;

}

.first {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
}



.first2 {
  float: left;
  height: 100px;
  width: 100px;
  border: 1px solid red;
  background-color: rgba(0, 0, 0, 0.9);
}