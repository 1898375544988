.inviteContainer {
    max-width: 762px;
    min-height: 557px;
    background: rgba(6, 12, 14, 1) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 10px !important;
}

.inviteFriendBody {
    max-width: 390px;
    margin: auto;
    width: 100%;
    padding: 0px 6px;
}

.inviteFriend_Header {
    padding-top: 34px;
    display: flex;
    align-items: baseline;
}

.invite_Title {
    margin-left: 100px;
}

.invite_Title h5 {
    text-align: left;
    font: normal normal medium 24px/28px Rubik !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 24px;
    font-family: Rubik;
    line-height: 28px;
}

.friendDetail_Area {
    padding-top: 35px;
}

.friendDetail_Area label {
    text-align: left;
    font: normal normal normal 12px/22px Rubik;
    letter-spacing: 0px;
    color: #A8A4A4;
    opacity: 1;
}

.email_inviteInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.email_inviteInput input {
    max-width: 70%;
    width: 100% !important;
    border: 1px solid #707070;
    background-color: transparent !important;
    border-radius: 5px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    font-family: Rubik;
    height: 43px;
    width: 270px;
    padding-left: 15px;
    margin-right: 5px;
}

p.spacing {
    padding-bottom: 4px;
}

.email_inviteInput p {
    max-width: 30%;
    width: 100%;
    text-align: left;
    font: normal normal medium 16px/19px Rubik;
    letter-spacing: 0px;
    color: #29AAE3;
    opacity: 1;
    cursor: pointer;
    margin-bottom: 0px !important;
}

.emailAddress p {
    text-align: right;
    font: normal normal normal 14px/22px Rubik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;

}

.emailAddress {
    display: flex;
    justify-content: space-between;
    max-width: 270px;
}

.invite_btn {
    background: #29AAE3 0% 0% no-repeat padding-box;
    border: 1px solid #29AAE3;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    /* height: 44px; */
}

.invite_btn button {
    color: #000000;
    font-size: 16px;
    padding: 8px 0px;
    width: 100%;
    font-weight: 500;
}

.for_Error_Msg {
    margin: 140px 0px 0px 0px;
    padding-bottom: 15px;
}

.forSuccess {
    color: green;
    margin-bottom: 3px;
    font-size: 14px;
}

.forError {
    color: #d80000;
    margin-bottom: 3px;
    font-size: 13px;
}