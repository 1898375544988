* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

/*  */
.fullsection {
  top: 0px;
  right: 0px;
  left: 0px;
  position: sticky;
  margin-bottom: 20px;
}

.headercontainer {
  width: 100%;
  height: 100%;
  background: #010710;
  display: flex;
  align-items: center !important;
  justify-content: space-between;

  height: 100%;
  min-height: 65px;
}

.headercontainer > div {
  margin-right: 10px;
  margin-left: 10px;
}

#logoutButton {
  margin-right: 18px;
  margin-left: auto;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.headernav {
  align-self: end;
}

.headernav ul {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

ul li a {
  position: relative;
  align-items: center;
  margin: 0 15px;
}

.headernav a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -19px;
  width: 100%;
  height: 3px;
  background: #29aae3;
  opacity: 0;
}

.navvaricons svg {
  vertical-align: middle;
}

.navvaricons .myhistorylogo svg {
  fill: #737374;
}

.Tutoraillogo a svg {
  width: 25px;
  height: 25px;
  fill: #737374;
}

.infologo a svg {
  width: 25px;
  height: 25px;
  fill: #737374;
}

.navvaricons .infologo a svg:focus {
  fill: #29aae3;
  stroke: #29aae3;
}

.navvaricons p {
  color: #737374;
  padding-left: 10px;
  text-align: center;
  display: inline;
  text-align: center;
  caret-color: rgba(0, 0, 0, 0);
}

.navvaricons .Tutoraillogo a svg:focus #Tutorial {
  fill: #29aae3;
  stroke: #29aae3;
}

nav ul li a:focus svg {
  fill: #29aae3;
  stroke: #29aae3;
}

nav ul li a:focus #History #scheduling {
  fill: #29aae3;
  stroke: #29aae3;
}

nav ul li a:focus #scheduling #Group-4 {
  fill: #29aae3;
  stroke: #29aae3;
}

nav ul li a:focus p {
  color: #29aae3;
}

nav ul li a:focus:after {
  opacity: 1;
}

/* focus */
nav ul li a.active svg {
  fill: #29aae3;
  stroke: #29aae3;
}

nav ul li a.active #History #scheduling {
  fill: #29aae3;
  stroke: #29aae3;
}

nav ul li a.active #scheduling #Group-4 {
  fill: #29aae3;
  stroke: #29aae3;
}

nav ul li a.active p {
  color: #29aae3;
  caret-color: rgba(0, 0, 0, 0);
}

nav ul li a.active:after {
  opacity: 1;
}

/* focus end */

.user {
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.user img {
  vertical-align: sub;
}

.user .useremail {
  align-self: flex-end;
}

.userPlaceholder {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.user p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  caret-color: rgba(0, 0, 0, 0);
  margin-bottom: 11px;
}

.downArrow {
  width: 9px;
  height: 12px;
  margin-left: 12px;
  cursor: pointer;
  position: relative;
  margin-bottom: 4px;
}

.userdropdown {
  position: absolute;
  right: 50px;
  top: 90px;
  background-color: rgb(8, 7, 7);
  padding: 20px;
  width: 195px;
  text-align: right;
  border-radius: 0 0 10px 10px;
  transition: all 0.3s ease-in;
  transform: translateY(-10px);
  display: none;
}

.dropdownchild {
  position: absolute;
  right: 5px;
  top: 70px;
  background-color: rgb(8, 7, 7);
  padding: 20px;
  padding-top: 0px;
  width: 195px;
  text-align: right;
  border-radius: 0 0 10px 10px;
  transition: all 0.3s ease-in;
  transform: translateY(-10px);
  display: block;
  font-weight: 300;
}

ul li button {
  color: white;
  font-weight: 300;
  padding-right: 6px;
}

ul li a {
  color: rgb(252, 250, 250) !important;
}

button {
  border: none;
  background: none;
}

ul a {
  text-decoration: none;
}

input:focus {
  border: none;
  outline: none;
}

a {
  text-decoration: none !important;
}

/* responsive */

@media screen and (max-width: 1274px) {
  .headernav {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #010b17;
    width: 100%;
    margin-left: 0;
    margin-right: 0px;
    z-index: 999;
  }

  .headernav ul {
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    left: 0;
    max-height: 40px;
  }

  .headernav ul li {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 905px) {
  .headernav ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    justify-content: center;
  }

  a::after {
    display: none;
  }
}

@media only screen and (max-width: 653px) {
  .logo {
    width: 50px;
    overflow: hidden;
  }
}

@media screen and (max-width: 653px) {
  .user p {
    z-index: 5;
  }

  .downArrow {
    margin-left: 0;
  }
}

@media screen and (max-width: 569px) {
  .navvaricons p {
    display: inline-block;
    text-align: center;
  }
}

/* .show {
  display: none;
} */

.add_overflow .show {
  display: flex;
  justify-content: center;
}

.likelogo p {
  white-space: nowrap;
}

.myhistorylogo p {
  white-space: nowrap;
}

@media screen and (max-width: 1331px) {
  ul li a {
    margin: 0 10px;
  }
}

/* new css work header */
/* new css */
p.toggleArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.notifyPopup {
  z-index: 999;
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  top: 1px;
  right: 0px;
  transform: translateY(65px);
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 350px;
  width: 100%;
  border: 1px solid #338dcf;
}

.toggleArea span {
  font-size: 17px;
  color: white;
  font-weight: 600;
  margin-bottom: 0;
}

.noNotification {
  display: flex;
  flex-wrap: wrap;
  width: 390px;
  font-size: 1rem;
  font-weight: 500;
  color: #212529;
  text-align: left;
  padding: 0px 10px;
}

.popupHide {
  display: none;
}

.notificationItems {
  min-height: 330px;
  max-height: 330px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

.NavIcons .nav-link {
  padding: 0px 7px !important;
}

.react-toggle--checked .react-toggle-thumb {
  left: 23px !important;
  top: 2px !important;
}

.react-toggle-track {
  width: 49px !important;
  height: 29px !important;
  padding: 0;
  border-radius: 30px;
  background-color: #ccc !important;
}

.react-toggle-thumb {
  top: 2px !important;
  left: 2px !important;
}

.react-toggle-thumb {
  width: 24px !important;
  height: 24px !important;
}

.react-toggle-track-check {
  display: none;
}

.react-toggle-track-x {
  display: none;
}

.react-toggle--checked .react-toggle-track {
  background-color: #338dcf !important;
}

.react-toggle--checked .react-toggle-track:hover {
  background-color: #338dcf !important;
}

.react-toggle--checked .react-toggle-thumb {
  border: #338dcf !important;
}

.react-toggle--focus .react-toggle-thumb:focus {
  box-shadow: none !important;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
}

.react-toggle:active:not(.react-toggle--disabled).react-toggle-thumb {
  box-shadow: none !important;
}

.react-toggle-thumb {
  border: none !important;
}

.react-toggle-track:hover {
  background-color: #ccc !important;
}

.react-toggle-thumb :hover {
  background-color: #ccc !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: none !important;
}

.toggleArea .dropdown-item {
  background-color: #ffffff !important;
}

.notifyItems .setIconArea {
  align-self: baseline;
  text-align: center;
  background-color: #29aae3;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.setIconArea img {
  height: 50px;
  width: 50px;
  object-fit: scale-down;
}

/* .setIconArea img {
  object-fit: fill;
  border-radius: 50%;
  max-width: 50px;
  max-height: 50px;
  width: 100%;
  height: 100%;
} */

.notifyItems {
  width: 100%;
  margin-bottom: 5px;
  background: #323030;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.notifyContant {
  text-align: left;
}

.notifyContant h6 {
  color: white;
  margin: 0px;
}

.notifyContant p {
  color: white !important;
  font-size: 14px;
  font-weight: 300;
  margin: 0px;
  word-break: break-word;
}

.activeClass {
  background-color: #58b0e0;
}

.activeClass:hover {
  background-color: #58b0e0;
}

.emojihover:hover {
  color: black !important;
}

.emojihover:visited {
  color: black !important;
}

/* new header work */

.header_nav {
  justify-content: center !important;
  display: flex;
  width: 100%;
}

.me-auto {
  width: 100% !important;
}

.toogle_header {
  position: relative;
  background-color: #29aae3 !important;
}

.header_nav > div {
  margin-left: 30px !important;
  align-self: end !important;
}

.header_dropdown {
  display: flex;
  align-items: center;
}

.header_dropdown > div {
  margin-left: 10px !important;
}

.header_navs_flex {
  display: flex;
  align-items: baseline;
}

.header_navs_flex > div {
  margin-left: 10px !important;
  align-self: end;
}

.header_navs_flex:active #History {
  fill: #58b0e0 !important;
}

.dropdown-menu[data-bs-popper] {
  border: 1px solid #58b0e0 !important;
  left: unset !important;
}

.navbar {
  justify-content: space-between !important;
  width: 100% !important;
  flex-wrap: nowrap !important;
  background-color: #060c0e !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  align-items: center !important;
}

.navbar-expand-lg .navbar-collapse {
  align-items: baseline;
}

.dropdown-toggle::after {
  display: none !important;
}

.navbar-nav {
  align-items: center;
}

.dropdown-menu {
  color: white !important;
  background-color: #060c0e !important;
}

.dropdown-item {
  color: white !important;
  background-color: #060c0e !important;
}

.dropdown-item:hover {
  color: white !important;
  background-color: #060c0e !important;
}

.dropdown-item.active {
  color: #fff !important;
  text-decoration: none;
  background-color: #060c0e !important;
}

.dropdown-menu {
  border-radius: 10px !important;
  top: 99% !important;
  right: 0px !important;
}

.header_nav p {
  padding: 0px 5px;
  margin-bottom: 0px !important;
  color: grey !important;
}

#Myhistory {
  fill: grey !important;
}

.header_username {
  max-width: 200px;
}

@media screen and (max-width: 1050px) {
  .header_nav > div {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 550px) {
  .new_header_logo {
    max-width: 55px !important;
  }
}

@media screen and (max-width: 1200px) {
  .header_username {
    display: none !important;
  }

  .header_nav > div {
    margin-left: 10px !important;
  }
}

@media screen and (max-width: 991px) {
  .new_header_menuitem {
    align-self: flex-start;
    margin-top: 10px;
    margin-left: 10px;
    order: 3 !important;
  }

  .navbar {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .new_header_logo {
    margin: 10px;
    max-width: 265px;
    overflow: hidden;
    width: 100%;
    margin-right: 10px;
    order: 1 !important;
    align-self: flex-start;
  }

  .user_notification {
    align-self: flex-start;
    order: 2 !important;
    margin-top: 10px;
  }

  .navbar-nav {
    display: block !important;
  }

  /* .show.active::after .navbar-nav {
    align-items: center;
  } */

  .header_nav {
    display: block !important;
  }

  .NavIcons {
    margin-left: 17px;
  }

  .dropdown-menu {
    max-width: 200px !important;
    background-color: #000000 !important;
  }

  .dropdown-item {
    color: white !important;
    background-color: #000000 !important;
  }

  .header_nav > div {
    padding: 10px 0px;
    margin-left: 0px !important;
  }

  .header_navs_flex > div {
    margin-left: 0px !important;
  }

  .navmenu a.active .header_navs_flex::after {
    content: "";
    position: absolute;
    left: 9px;
    bottom: -21px;
    width: 92%;
    height: 4px;
    background: #29aae3;
    display: none !important;
  }

  .header_nav a.active p {
    color: #29aae3 !important;
  }

  .header_navs_flex > div {
    align-self: center;
  }
}

.header_nav a.active p {
  color: #29aae3 !important;
}

.header_nav a:focus p {
  color: #29aae3 !important;
}

.header_nav a.active #scheduling #Group-4 {
  fill: #58b0e0 !important;
  stroke: #29aae3 !important;
}

.header_nav a:focus #scheduling #Group-4 {
  fill: #58b0e0 !important;
  stroke: #29aae3 !important;
}

.header_nav a.active svg {
  fill: #58b0e0 !important;
  stroke: #29aae3 !important;
}

.header_nav a:focus svg {
  fill: #58b0e0 !important;
  stroke: #29aae3 !important;
}

.navbar-brand {
  margin-left: 10px;
  margin-right: -30px !important;
}

.navbar-toggler {
  margin-right: 10px;
}

.navmenu a {
  position: relative;
}

button {
  font-size: 16px;
  line-height: 19px !important;
  font-weight: bold !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-feature-settings: normal !important;
  font-family: inherit !important;
}

.navmenu a.active .header_navs_flex::after {
  content: "" !important;
  text-decoration: underline !important;
  position: absolute !important;
  left: 9px !important;
  bottom: -20px !important;
  width: 93% !important;
  height: 4px !important;
  background: #29aae3 !important;
  display: block;
}

.header_navs_flex::after {
  content: "" !important;
  text-decoration: underline !important;
  position: absolute !important;
  left: 9px !important;
  bottom: -20px !important;
  width: 93% !important;
  height: 4px !important;
  background: #29aae3 !important;
  display: none;
}

.notification_logout {
  display: flex;
  align-items: center;
}

.notification_logout > div {
  margin: 0px 10px;
}

.addmore_button {
  height: 40px;
  background: #58b0e0;
  border: 1px solid #58b0e0;
  text-align: center;
  width: 100%;
  max-width: 117px;
  border-radius: 7px;
  margin-left: 13px;
}

.addmore_button_flex {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.addclassread {
  margin-top: 0px;
  margin-bottom: 5px;
  background-color: transparent !important;
}

.header_menu_image {
  align-self: center;
}

.header_username p {
  white-space: break-spaces;
  word-break: break-word;
  margin-bottom: 0px;
}

body.swal2-toast-shown .swal2-container {
  z-index: 9999999999 !important;
}

.navicons {
  position: relative;
}

/* .navicons::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #f71700;
  border-radius: 50%;
  top: 1px;
  left: 14px;
} */

.bellactive {
  color: #4cabe3;
}

.bellnotactive {
  color: grey;
}

@media screen and (max-width: 400px) {
  .notification_logout {
    margin-left: -40px;
  }
}

.displayadd .displaynoe {
  display: none;
}

.displayadd .displayblock {
  display: block;
}

.bellnotactive {
  cursor: pointer;
}

.bellactive {
  cursor: pointer;
}

.showunreadmsg {
  cursor: pointer;
  background-color: #29aae3;
  top: -6px;
  right: -7px;
  position: absolute;
  height: 17px;
  min-width: 17px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-size: 12px;
}

.nav-link {
  padding: 0px 0px !important;
}

/* .header_nav a svg {
  vertical-align: unset;
} */

.notification {
  display: block;
  position: fixed;
  top: 80px;
  right: 10px;
  z-index: 9999999999999999999999999999999999999999999999999999999999999;
}

.notificationbody {
  width: 100%;
  min-width: 250px;
  max-width: 320px;
  border-radius: 12px;
  border: 1px solid #29aae3;
}

.notificationbody p {
  color: white;
  word-break: break-all;
  margin-bottom: 0px;
  font-weight: 700;
  letter-spacing: 1px;
  /* margin-top: 10px; */
}

.notification_title {
  height: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #29aae3;
  border-radius: 2px; */
}

.notification_title > div {
  margin: 0px 10px;
}

.notification_dis {
  height: 100%;
  min-height: 50px;
}

.notification_dis p {
  color: #d9d9d9 !important;
  font-weight: 400;
  letter-spacing: inherit;
  font-size: 14px;
  margin-top: -10px !important;
}

.noti_cross_icon {
  display: flex;
  right: 2px;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.noti_cross_icon svg {
  color: white;
  font-size: 12px;
}

.notification_dis > div {
  margin: 0px 10px;
}

.no_notification {
  display: none;
}
