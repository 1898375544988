.setReminder_Container {
    max-width: 762px;
    min-height: 557px;
    background: rgba(6, 12, 14, 1) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 10px !important;
}

.setReminder_Body {
    max-width: 390px;
    width: 100%;
    margin: auto;
    padding: 0px 6px;
}

.setReminder_Header {
    padding-top: 34px;
    display: flex;
    align-items: baseline;
}

.reminder_Title {
    margin-left: 90px;
}

.reminder_Title h5 {
    text-align: left;
    font: normal normal medium 24px/28px Rubik !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 24px;
    font-family: Rubik;
    line-height: 28px;
}

.setReminder_daily p {
    text-align: left;
    font: normal normal medium 16px/19px Rubik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.setReminder_daily {
    padding-top: 45px;
    margin-left: 6px;
    display: flex;
    justify-content: space-between;
}

.react-toggle-track {
    width: 50px !important;
    height: 30px !important;
    background-color: #4D4D4D !important;
}

.react-toggle--checked .react-toggle-thumb {
    left: 23px !important;
    top: 2px !important;
}

.react-toggle-thumb {
    top: 3px !important;
    left: 2px !important;
}

.react-toggle-thumb {
    width: 25px !important;
    height: 25px !important;
}

.react-toggle-track-check {
    display: none;
}

.react-toggle-track-x {
    display: none;
}

.react-toggle--checked .react-toggle-track {
    background-color: #29AAE3 !important;
}

.react-toggle--checked .react-toggle-track:hover {
    background-color: #29AAE3 !important;
}


.react-toggle--checked .react-toggle-thumb {
    border: #29aae3 !important;
}

.react-toggle--focus .react-toggle-thumb:focus {
    box-shadow: none !important;
}

.react-toggle--focus .react-toggle-thumb {
    box-shadow: none !important;
}

.react-toggle:active:not(.react-toggle--disabled).react-toggle-thumb {
    box-shadow: none !important;
}

.react-toggle-thumb {
    border: none !important;
}

.time_Slot span {
    text-align: left;
    font: normal normal normal 14px/17px Rubik;
    letter-spacing: 0px;
    color: #A8A4A4;
    opacity: 1;
}

/* .time_Slot input {
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    font-family: Rubik;
    height: 43px;
    width: 101px;
    text-align: center;
} */

.time_Slot {
    /* display: flex;
    flex-direction: column; */
    margin-left: 6px;
    padding-bottom: 7px;
    padding-top: 20px;
}

.reminder_Save_btn {
    background: #29AAE3 0% 0% no-repeat padding-box;
    border: 1px solid #29AAE3;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    /* height: 44px; */
    margin: 0px 0px 0px 3px;
}

.reminder_Save_btn button {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 0px;
    width: 100%;
}

.react-toggle-track:hover {
    background-color: #4D4D4D !important;
}

.react-toggle-thumb :hover {
    background-color: #4D4D4D !important;
}

.react-toggle {
    /* background-color: #4D4D4D !important;
    border-radius: 38%; */
    height: 30px;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: none !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: none !important;
}

.remindLoader {
    margin-top: 169px;
}

/* .react-time-picker__wrapper {
    background: #1f252a !important;
    border: 1px solid #707070 !important;
    text-align: center !important;
    border-radius: 6px !important;
    height: 43px !important;
}

.react-time-picker__inputGroup__divider {
    color: white !important;
}

.react-time-picker__button {
   padding: 0px !important;
}

.react-time-picker__inputGroup {
    padding: 0 14px 0px 4px !important;
} */

.react-datepicker-time__header {
    display: none !important;
}

.react-datepicker__time-list {
    font-size: 15px !important;
    cursor: pointer !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    top: -1px !important;
    left: -12px !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    top: -1px;
    left: -12px !important;
    border-bottom-color: #aeaeae;
}

.reminder_Time input {}

.reminder_Time input[type="text"]:focus {
    border: 1px solid gray !important;
}

.reminder_Time input[type="text"] {
    background-color: transparent !important;
    border: 1px solid gray;
    text-align: center !important;
    max-width: 26% !important;
    width: 100%;
    height: 45px;
    border-radius: 6px;
}

.reminder_Time input[type="text"]:disabled {
    color: gray !important;
    border: 1px solid gray !important;
}

.reminder_Time input[type="text"]:enabled {
    color: white !important;
    border: 1px solid white !important;
}