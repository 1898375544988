.HeadContain {
    background: #262323;
    display: flex;
    padding: 12px 50px;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}

.logoArea img {
    width: 100%;
}

.HeadingArea h4 {
    font-size: 20px;
    color: white;
}