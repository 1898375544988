.aboutUs_Container {
    max-width: 762px;
    min-height: 557px;
    background: rgba(6, 12, 14, 1) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 10px !important;
}

.aboutUs_Body {
    max-width: 610px;
    margin: auto;
    padding: 0px 10px;
}

.aboutUs_Header {
    display: flex;
    padding-top: 36px;
}

.aboutUs_Title {
    margin-left: 225px;
}

.aboutUs_Title h5 {
    text-align: left;
    font: normal normal medium 24px/28px Rubik !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 24px;
    font-family: Rubik;
    line-height: 28px;
}

p.title_Explain {
    text-align: center;
    font: normal normal normal 12px/21px Rubik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding-top: 10px;
}

.aboutUs_Area {
    max-height: 400px;
    padding-right: 5px;
    overflow-y: scroll;
}

.aboutUs_Area::-webkit-scrollbar {
    width: 8px;
}

.aboutUs_Area::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #737272;
    border: 1px solid #000;
}

.aboutUs_Area::-webkit-scrollbar-corner {
    display: none;
}


/* ---------------------------- */

.stress_Body {
    padding-top: 20px;
}

.Stress_Contain h5 {
    text-align: left;
    font: normal normal normal 18px/21px Rubik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.Stress_Contain p {
    text-align: left;
    letter-spacing: 0px;
    color: #CFCECE;
    opacity: 1;
    font-size: 13px;
    font-weight: normal;
    font-family: sans-serif;
}


.build_stress_Icon {
    border: 1px solid #4cabe3;
    border-radius: 50%;
    width: 60px;
    height: 57px;
    margin: auto;
}

.build_stress_Icon img {
    width: 40px;
    margin: 5px 1px 1px 9px;
}

.Stress_Contain {
    margin-top: 5px;
}

/* -------------------------------- */

.improve_Body {
    padding-top: 20px;
}

.improve_Task_Icon {
    border: 1px solid #4cabe3;
    border-radius: 50%;
    width: 60px;
    height: 58px;
    margin: auto;
}

.improve_Task_Icon img {
    width: 40px;
    margin: 8px 1px 1px 9px;
}

.improve_Contain {
    margin-top: 5px;
}

.improve_Contain h5 {
    text-align: left;
    font: normal normal normal 18px/21px Rubik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.improve_Contain p {
    text-align: left;
    letter-spacing: 0px;
    color: #CFCECE;
    opacity: 1;
    font-size: 13px;
    font-weight: normal;
    font-family: sans-serif;
}

/* ----------------------------------- */


.fall_Body {
    padding-top: 20px;
}

.all_Asleep_Icon {
    border: 1px solid #4cabe3;
    border-radius: 50%;
    width: 60px;
    height: 58px;
    margin: auto;
}

.all_Asleep_Icon img {
    width: 40px;
    margin: 8px 1px 1px 9px;
}

.asleep_Contain {
    margin-top: 5px;
}

.asleep_Contain h5 {
    text-align: left;
    font: normal normal normal 18px/21px Rubik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.asleep_Contain p {
    text-align: left;
    letter-spacing: 0px;
    color: #CFCECE;
    opacity: 1;
    font-size: 13px;
    font-weight: normal;
    font-family: sans-serif;
}

/* ---------------------------- */


@media screen and (max-width:992px) {
    .all_Asleep_Icon {
        width: 100px;
        height: 100px;
    }

    .all_Asleep_Icon img {
        width: 72px;
        margin: 12px 1px 1px 13px;
    }

    /* -------------------------- */

    .improve_Task_Icon {
        width: 100px;
        height: 100px;
    }

    .improve_Task_Icon img {
        width: 75px;
        margin: 12px 1px 1px 12px;
    }

    /* ------------------------------- */


    .build_stress_Icon {
        width: 100px;
        height: 97px;
    }

    .build_stress_Icon img {
        width: 70px;
        margin: 9px 1px 1px 17px;
    }

    /* -------------------------------- */
}

@media screen and (max-width:450px) {
    .aboutUs_Title {
        margin-left: 140px;
    }
}

.row {
    --bs-gutter-x: 0px !important;
    --bs-gutter-y: 0px !important;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}