.historyflexbody {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 75px;
}

.historyflexbodysetting {
  display: flex;
  justify-content: center;
  width: 100%;
}


.historypopup {
  max-width: 100%;
  width: 100%;
}

.HisText h3 {
}

.openpopup {
  max-width: 40%;
  width: 100%;
}

.HistoryContain {
  max-width: 670px;
  margin: auto;
  border-radius: 14px;
  background-color: rgba(0, 0, 0, 0.9);
  min-height: 620px;
  margin-bottom: 10px;
}

.HistList {
  text-align: center;
  color: #a0a0a0;
}

.HistHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px;
  padding-top: 35px;
}

.HisTitle h2 {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
}


.HisBtn {
  z-index: 1;
}

.ClrBtn {
  z-index: 1;
}

.HisBtn a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

.HisBtn a:hover {
  color: #fff;
}

.HisBtn img {
  margin-right: 7px;
}



.HistList {
  overflow: scroll;
  max-height: 550px;
  max-width: 630px;
  width: 100%;
  margin: auto;
}

.HistItem {
  max-width: 630px;
  width: 100%;
  background-color: #1f252a;
  border-radius: 6px;
  margin-bottom: 12px;
}

.HistItem a {
  max-width: 630px;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 20px;
}

.HistItem h3 {
  margin-bottom: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.HistItem span {
  color: #a0a0a0;
  font-size: 12px;
  font-weight: 400;
}

.HistItem img {
  width: 16px;
}

.notrecord {
  text-align: center;
  color: gray;
}

@media screen and (max-width: 768px) {
  .HistItem a {
    text-decoration: none;
    display: block;
    align-items: center;
    justify-content: space-between;
  }

  .HistoryContain {
    max-width: 670px;
    margin: auto;
    border-radius: 14px;
    background-color: rgba(0, 0, 0, 0.9);
    min-height: 620px;
    margin-bottom: 10px;
  }

  .HistList {
    max-width: 630px;
    width: 100%;
    margin: auto;
    padding-bottom: 20px;
    padding: 0px 10px;
  }
}

/* Responsive Area */

@media screen and (max-width: 1000px) {
  .HistItem a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .HistoryContain {
    max-width: 670px;
    margin: auto;
    border-radius: 14px;
    background-color: rgba(0, 0, 0, 0.9);
    min-height: 620px;
  }

  .HistList {
    max-width: 630px;
    width: 100%;
    margin: auto;
    padding-bottom: 20px;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 1400px) {
  .HistItem a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .HistoryContain {

    max-width: 670px;
    margin: auto;
    border-radius: 14px;
    background-color: rgba(0, 0, 0, 0.9);
    min-height: 620px;
  }

  .HistList {
    max-width: 630px;
    width: 100%;
    margin: auto;
    padding-bottom: 20px;
    padding: 0px 10px;
  }

}

@media screen and (max-width: 1400px) and (min-width:992px) {
  .historyflexbody>div {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .historyflexbody {
    display: flex;
    flex-direction: column;
  }

  .add_overflow .show {
    max-width: 800px;
    color: black;
    justify-content: center;
    padding-bottom: 10px;
  }



  .add_overflow .historypopup {
    padding-top: 10px !important;
    margin-bottom: 20px;
  }



  .introVdoBox {
    margin-top: 20px !important;
  }



}

.historyLoader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: 100vh;
  text-align: center;
  z-index: -1;
}

span.historyLoader img {
  width: 60%;
}

/* .historyLoader img {
  max-width: 174px;
  width: 100%
} */

.HistIcon img {
  margin-left: 10px;
}

.iconcss {
  height: 10px;
}


.icon {
  size: 40px 40px;
}

@media only screen and (max-width: 450px) {
  .HistItem a {
    flex-wrap: wrap !important;
    display: flex !important;
  }
}

.HisText {
  max-width: 50%;
  width: 100%;
}

.HistIcon {
  text-align: right;
  max-width: 50%;
  width: 100%;
}