.confirmAlert {
  z-index: 99;
  padding: 150px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmAlertBox {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff !important;
  text-align: center !important;
  border: 2px solid #355d6f;
  font-family: Arial, Helvetica, sans-serif;
  width: 470px;
  min-height: 300px;
  padding: 0px 10px;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
}

.confirmAlertBtn {
  padding-top: 20px;
  display: flex;
  max-width: 300px;
  margin: auto;
}

.confirmAlertBtn button {
  background: #29aae3;
  color: #fff;
  padding: 10px 55px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  border: none;
  width: 100%;
  margin-top: 20px;
}


.confirmAlertBox b {
  font-size: larger;
}

.confirmAlertBox p {
  max-width: 300px;
  margin: auto;
  padding: 10px 0px;
}

.confirmAlertBox h5 {
  padding-top: 40px;
  padding-bottom: 10px;
}

.yesBtn {
  margin-right: 5px;
}

.noBtn {
  margin-left: 5px;
}

@media screen and (max-width: 476px) {}