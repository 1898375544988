.contain_Area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 150px 0px;
}

.Contain-text {
    color: black;
    text-align: center;
}

.Contain-text h1 {
    font-size: 175px;
}

.Contain-text h3 {
    font-size: 60px;
}

.Contain-text h2 {
    font-size: 80px;
}

.img_Conatin {
    align-self: flex-end;
    max-width: 100%;
}

.img_Conatin img {
    width: 330px;
}

/* ----Responsive Area---- */

@media screen and (max-width: 800px) {
    .contain_Area {
        padding: 200px 0px;
    }

    .img_Conatin img {
        display: none;
    }
}

@media screen and (max-width: 300px) {
    .Contain-text h1 {
        font-size: 150px;
    }
}