* {
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

.ForGotBody {
  padding-bottom: 40px;
}

.forgotbodyflex {
  max-width: 670px;
  margin: auto;
  width: 100%;
}

.ForgotLogo {
  padding-top: 40px;
  padding-bottom: 10px;
}

.forgotform {
  margin-left: 5px;
  margin-right: 5px;
}

.ForgotConatin {
  display: flex;
  justify-content: space-around;
}

.ForgotConatin>div {
  margin: 3px;
}

.forgotouterbody {
  max-width: 445px;
  width: 100%;
  margin: auto;
}



.forgotinnerbody {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  border-radius: 15px;
  max-width: 670px;
  overflow: hidden;
  /* min-height: 400px;
} */
  padding: 25px;
}

.ForgotHead {
  text-align: center;
}

.arrowimage a {
  margin: 0px;
  padding: 0px;
}

.ForgotHead h3 {
  color: white;
  line-height: 35px;
}

.ForgotHead span {
  color: gray;
  font-size: 13px;
  font-weight: 400;
}

.Form_Area {
  padding-top: 27px;
}



.Form_Area button:hover {
  color: #fff;
}

.forgothomebody {
  display: flex;
  width: 100%;
  background: #1f252a;
  height: 45px;
  padding: 5px 20px;
  border-radius: 5px;
}

.forgotformimage {
  margin-right: 15px;
}

.LinksArea {
  display: flex;
  padding-top: 12px;
  justify-content: center;
}

.LinksArea p {
  margin-bottom: 0px;
  font-weight: 300;
  color: #fff;
  font-size: 15px;
  margin-right: 8px;
}

.LinksArea a {
  color: #29aae3;
}

.LinksArea a:hover {
  color: #29aae3;
}

input:focus {
  outline: none;
}

.ForEmailError {
  color: red;
  font-size: 12px;
}

/* .ForError {
  color: red !important;
  font-size: 12px !important;
  line-height: 1px;
} */