.language_Container {
    max-width: 762px;
    min-height: 557px;
    background: rgba(6, 12, 14, 1) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    /* opacity: 1; */
    width: 100%;
    height: 100%;
    /* padding: 0px 185px 0px 185px; */
    margin: auto;
    margin-bottom: 10px !important;
}

.langauge_Body {
    max-width: 390px;
    width: 100%;
    margin: auto;
}

.language_Header {
    padding-top: 34px;
    display: flex;
    align-items: baseline;
}

.header_Title {
    margin-left: 120px;
}

.header_Title h5 {
    text-align: left;
    font: normal normal medium 24px/28px Rubik !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 24px;
    line-height: 28px;
}

.language_Field {
    padding-top: 25px;
}

.lang_Field_1 {
    padding: 0 10px;
}

.lang_Field_1 label {
    font-weight: 400;
    font-size: 15px;
    font-family: Rubik;
    display: flex;
    color: #fff;
    align-items: center;
    border: 1px solid #707070;
    justify-content: space-between;
    padding: 0px 20px;
    border-radius: 5px;
    height: 43px;
    margin-bottom: 16px;
    position: relative;
}

.lang_Field_1 input {
    position: absolute;
    height: 100%;
    width: 90%;
    opacity: 0;
    cursor: pointer;
}

.hideRadio {
    display: none;
}

.Save_Button {
    background: #29AAE3 0% 0% no-repeat padding-box;
    border: 1px solid #29AAE3;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    /* height: 44px; */
    margin: 0px 8px 0px 8px;
}

.Save_Button button {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    padding: 8px 0px;
}

.loaderImg {
    text-align: center;
    margin: 0px;
}

.removeSpace {
    margin-bottom: 39px;
}

.loaderImg img {
    width: 75px;
}

/* .lang_Field_1 input[type="radio"]{
    border: 4px solid red;
    width: 12px;
} */