.myData_Container {
    max-width: 762px;
    min-height: 557px;
    background: rgba(6, 12, 14, 1) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 10px !important;
}

.myData_Body {
    max-width: 429px;
    margin: auto;
    width: 100%;
    padding: 0px 6px;
}

.MyData_Header {
    padding-top: 34px;
    display: flex;
    align-items: baseline;
}

.MyData_Title {
    margin-left: 150px;
}

.MyData_Title h5 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 24px;
    font-family: Rubik;
    line-height: 28px;
    font-weight: 500;
}

.DataClear {
    text-align: left;
    font: normal normal medium 13px/17px Rubik;
    letter-spacing: 0px;
    color: #A8A4A4;
    opacity: 1;
    margin-bottom: 4px;
}

.clearAlert {
    text-align: left;
    font: normal normal normal 15px/19px Rubik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 20px;
}

.DataClear_Btn {
    background: #29AAE3 0% 0% no-repeat padding-box;
    border: 1px solid #29AAE3;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    width: 143px;
    height: 44px;
}

.DataClear_Btn button {
    text-align: left;
    font: normal normal medium 16px/19px Rubik;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
    width: 100%;
    text-align: center;
    padding: 10px 0px;
}

.makeDisable button {
    cursor: not-allowed !important;
    border-color: #257aa0 !important;
    background-color: #257aa0 !important;
    opacity: 0.8 !important;
}

.bottum_border {
    border: 1px solid #707070;
    opacity: 1;
    margin-top: 19px;
    margin-bottom: 20px;
}

.MyData_Manage {
    padding-top: 45px;
}

.DeleteAccount_Btn {
    background: #FF0000 0% 0% no-repeat padding-box;
    border: 1px solid #FF0000;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    width: 143px;
    height: 44px;
}

.DeleteAccount_Btn button {
    text-align: left;
    font: normal normal medium 16px/19px Rubik;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    width: 100%;
    text-align: center;
    padding: 10px 0px;
}

.download_Data span {
    color: #29AAE3;
    cursor: pointer;
}

.download_Data {
    text-align: left;
    font: normal normal normal 16px/19px Rubik;
    letter-spacing: 0px;
    opacity: 1;
    color: #ffffff;
    padding-top: 5px;
}