.manageSub_Conatainer {
    max-width: 850px !important;
    min-height: auto !important;
    background: rgba(6, 12, 14, 1) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 10px !important;
}

.heightManage {
    min-height: 600px !important;
}

.manageSub_Body {
    max-width: 679px !important;
    width: 100%;
    margin: auto;
    padding: 0px 6px;
}

.manageSub_Header {
    padding-top: 34px;
    display: flex;
    align-items: baseline;
}

.manageSub_Title {
    margin-left: 210px;
}

.manageSub_Title h5 {
    text-align: left;
    font: normal normal medium 24px/28px Rubik !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 24px;
    line-height: 28px;
    font-family: Rubik !important;
}

.main_Plan {
    padding-top: 56px;
    max-height: 400px;
    overflow-x: scroll;
    overflow-y: scroll;
    padding-bottom: 20px;
}

.main_Plan::-webkit-scrollbar {
    width: 10px;
}

.main_Plan::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #505050;
    border: 1px solid #000;
}

.main_Plan::-webkit-scrollbar-corner {
    display: none;
}


.cost_Area.col-lg-1 {
    padding: 0px;
}


.first_Plan button {
    min-width: 95px;
    width: 100%;
    height: 44px;
    background: #29AAE3 0% 0% no-repeat padding-box;
    border: 1px solid #29AAE3;
    border-radius: 5px;
    opacity: 1;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.disableBtn {
    cursor: not-allowed !important;
    background-color: grey !important;
    border: 1px solid grey !important;
}


.main_Plan_head th {
    color: #A8A4A4;
    font-size: 14px;
    font-family: Rubik;
    padding-bottom: 3px;
    font-weight: 400;
    padding-right: 5px;
}

table.main_Plan_head {
    width: 100%;
}

table.main_Plan_head tr {
    border-bottom: 1px solid #707070;
}

.remover_border {
    border: none !important;
}

.first_Plan td {
    color: #ffffff;
    font-size: 13px;
    font-family: Rubik;
    font-weight: 400;
    padding: 14px 0px;
    padding-right: 5px;
}

.for_ScrollBar {
    max-height: 181px;
    overflow: scroll;
}

.for_ScrollBar::-webkit-scrollbar {
    width: 10px;
}

.for_ScrollBar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #737272;
    border: 1px solid #000;
}

.for_ScrollBar::-webkit-scrollbar-corner {
    display: none;
}

.other_Plan h5 {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 20px;
    line-height: 23px;
    font-family: Rubik;
    font-weight: 500;
    padding-top: 36px;
    padding-bottom: 18px;
}

.other_Plan td {
    color: #ffffff;
    font-size: 13px;
    font-family: Rubik;
    font-weight: 400;
    padding: 20px 5px 24px 0px;
}

td.checkbox_Icon {
    display: flex;
    align-items: end;
    position: relative;
}

td.checkbox_Icon input[type="checkbox"] {
    margin-right: 14px;
    cursor: pointer;
    width: 20px;
    opacity: 1;
    position: relative;
    height: 20px;
    z-index: 1;
    opacity: 0;
}

.border_check {
    position: absolute;
    content: "";
    width: 22px;
    height: 22px;
    border: 1px solid #707070;
    border-radius: 2px;
}

.checkBoxClass:checked~.border_check {
    border-color: #29AAE3;
    background-color: #29AAE3;
}

.checkBoxClass:checked~.border_check::after {
    display: block;
}

.border_check::after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 8px;
    height: 16px;
    border: solid rgb(7, 6, 6);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.checkbox_Icon span {
    margin-right: 3px;
}

.checkboxCustom {
    width: 20px !important;
    height: 20px !important;
    position: relative !important;
    border-radius: 2px !important;
    cursor: pointer !important;
}

.checkBoxIcon {
    position: absolute;
    left: -4 !important;
    bottom: -3px;
}

td.checkbox_Icon2 {
    display: flex;
    align-items: center;
}

td.checkbox_Icon2 svg {
    margin-right: 10px;
    cursor: pointer;
}

td.checkbox_Icon2 {
    margin-top: 7px;
}

.checkbox_icon_Hide {
    display: none;
}

.purchase_Button button {
    width: 123px;
    height: 44px;
    background: #29AAE3 0% 0% no-repeat padding-box;
    border: 1px solid #29AAE3;
    border-radius: 5px;
    font: normal normal 500 16px/19px Rubik;
    color: #000000;
}

.purchase_Button {
    padding-top: 18px;
    padding-bottom: 20px;
}

.cost_line {
    text-align: center;
}

@media screen and (max-width:1274px) {
    .purchase_Button {
        padding-bottom: 72px;
    }
}

@media screen and (max-width:670px) {
    td.checkbox_Icon2 {
        margin-top: 17px;
    }
}

@media screen and (max-width:500px) {
    .manageSub_Title {
        margin-left: 80px;
    }

    .first_Plan button {
        max-width: 95px;
        width: 100%;
    }

    td.checkbox_Icon2 {
        margin-top: 36px;
    }

    /* .manageSub_Body {
        padding-bottom: 70px;
    } */
}

@media screen and (max-width:400px) {
    td.checkbox_Icon2 {
        margin-top: 30px;
    }


}


.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.confirm-box__content {
    min-height: 225px;
}

.confirm-box__overlay {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(233, 225, 225, 0.1);
}