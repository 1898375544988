.RatingContainer {
  height: 100%;
  padding-bottom: 100px;
}

.RatingContain {
  max-width: 640px;
  background-color: rgba(0, 0, 0, 0.9);
  margin: auto;
  border-radius: 13px;
}

.BackBtn {
  padding-left: 15px;
  padding-top: 15px;
  position: relative;
}

/* svg.ratingicons1image:hover {
  border: 1px solid #4cabe3 !important;
} */
/* svg.ratingicons1image {
  transition: all 0.3s linear;
}

svg.ratingicons1image:hover {
  border: 1px solid #fff !important;
  /* box-shadow: 0px 0px 1px 5px #4cabe3; */

*/ .BackBtn p {
  border: 2px solid transparent;
  background-color: #1f252a;
  padding: 10px 18px;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 39px;
  height: 41px;
  border-radius: 50px;
  cursor: pointer;
}

.RatingSession {
  max-width: 520px;
  margin: auto;
  padding: 80px 0px 140px 0px;
}

.RatingTitle {
  max-width: 400px;
  margin: auto;
}

.RatingTitle h3 {
  line-height: 35px;
  color: #fff;
  font-weight: 500;
  width: 100%;
  margin: auto;
  padding-bottom: 15px;
  text-align: center;
}

.RatingItem {
  max-width: 440px;
  margin: auto;
  background-color: #1f252a;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContinueBtn {
  padding-top: 10px;
  text-align: center;
  max-width: 445px;
  margin: auto;
}

.ContinueBtn button {
  color: #fff;
  background-color: #29aae3 !important;
  padding: 10px 0px;
  width: 100%;
  height: 50px;
}

.ContinueBtn span {
  font-size: 19px;
  font-weight: 500;
}

.ContinueBtn .btn:focus {
  box-shadow: none;
}

.ContinueBtn .btn-primary:active:focus {
  box-shadow: none;
}

.star-svg {
  position: relative;
  width: 35px !important;
  height: 35px !important;
  border: 1px solid #29aae3;
  border-radius: 50%;
  display: inline-block;
  padding: 6px;
  margin: 0px 18px 0px 18px;
}

.ratingText {
  margin-top: 10px;
}

.ratingText1 p {
  position: absolute;
  left: 38px;
  font: normal normal normal 12px/14px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.ratingText2 p {
  position: absolute;
  right: 38px;
  font: normal normal normal 12px/14px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.RatingIcon_Text {
  position: relative;
  padding: 32px 0px 10px 0px;
}

.replay {
  background: transparent;
  border: 0;
  display: block;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  margin: 40px auto 0;
}

.replaybutton {
  display: flex;

  justify-content: center;
}

.Replaytitle {
  color: white;
}

.ratingicons {
  color: white;
  margin-left: 30px !important;
}

.fillColour {
  color: #29aae3;
  stroke: white;
}

.fillFirst {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: white;
}

.fillBackground {
  position: relative;
  z-index: 999;
}

.fillBackground small {
  position: absolute;
  background-color: white;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 0px;
  left: 34px;
  z-index: -999;
}

@media screen and (max-width: 590px) {
  .fillBackground small {
    position: absolute;
    background-color: white;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 0px;
    left: 29px;
    z-index: -999;
  }
}

@media screen and (max-width: 554px) {
  .fillBackground small {
    position: absolute;
    background-color: white;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 0px;
    left: 14px;
    z-index: -999;
  }
}

@media screen and (max-width: 390px) {
  .fillBackground small {
    position: absolute;
    background-color: white;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 0px;
    left: 10px;
    z-index: -999;
  }
}

@media screen and (max-width: 360px) {
  .fillBackground small {
    position: absolute;
    background-color: white;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 0px;
    left: 7px;
    z-index: -999;
  }
}

.ratingiconslastimage {
  margin-right: 30px !important;
  background-color: honeydew;
  border-radius: 50%;
  border: 3px solid white;
  position: relative;
}

.lastone::after {
  content: "Relax";
  color: white;
  position: fixed;
}

.hide-border svg {
  border: none;
}

.skipbutton {
  right: 4px;
  color: white;
  position: absolute;
  top: 330px;
  border: 1px solid gray;
  background: rgba(0, 0, 0, 0.9);
  padding: 4px px;
  border-radius: 5px;
  height: 35px;
}

.skipbutton .text {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 5px;
  margin-left: 10px;
}

.skipbutton .forwardimage h4 {
  margin-right: 10px !important;
}

.skipbuttons {
  right: 4px;
  color: white;
  top: 288px;
  padding: 4px px;
  border-radius: 5px;
  height: 35px;
}
.skipbuttons:hover {
  color: #29aae3;
}

.skipbuttons .text {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 5px;
  margin-left: 10px;
}

.skipbuttons .forwardimage h4 {
  margin-right: 10px !important;
}

.ratingicons1image::after {
  position: absolute;
}

/* newRatingwork */
.Ratingfullbody {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.Ratingupperbody {
  margin-bottom: 10px;
  max-width: 614px;
  min-height: 516px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 100px;
  border-radius: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.RatingItems {
  max-width: 514px;
  min-height: 119px;
  height: 100%;
  width: 100%;
  background: #1f252a 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.Ratingupperbody .Ratingtitle h3 {
  margin-top: 18px;
  text-align: center;
  font: normal normal 500 24px/28px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.rateleavefeedback p {
  text-align: left;
  font: normal normal 500 14px/2px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 53px;
}

.leavefeedbackbox {
  max-width: 511px;
  min-height: 108px;
  border-radius: 5px;
  opacity: 1;
  height: 100%;
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 10px 0px 10px; */
}

.leavefeedbackbox textarea {
  height: 107px;
  width: 100%;
  background: transparent;
  color: #fff;
  padding: 8px 12px 8px 12px;
}

/* .leavefeedbackbox textarea:focus-visible {
  border: #1f252a;
} */

.leavefeedbackbox p {
  text-align: justify;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin: auto;
}

.ratingitemdetail {
  margin-top: 30px;
  max-width: 509px;
  min-height: 96px;
  background: #1f252a 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.ratingitemdetail .itemdetail {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.ratingitemflexbody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rate_relax {
  font: normal normal 500 18px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px !important;
}

.rate_relax2 {
  font: normal normal normal 12px/14px Rubik;
  letter-spacing: 0px;
  color: #a8a4a4;
  opacity: 1;
  margin-bottom: 0px !important;
  margin-top: 4px !important;
}

.ratingitemflexbody>div {
  margin: 10px;
}

.Ratingsubmitbutton {
  max-width: 408px;
  width: 100%;
  min-height: 44px;
  height: 100%;
  background: #29aae3 0% 0% no-repeat padding-box;
  border: 1px solid #29aae3;
  border-radius: 5px;
  opacity: 1;
  font: normal normal 500 16px/19px ;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.Ratingbuttonupper {
  width: 100%;
  display: flex;
  margin-top: 44px;
  align-items: center;
  justify-content: center;
  margin-top: 44px;
}

.ratingimageflex {
  align-self: baseline;
}

@media screen and (max-width: 500px) {
  /* .ratingicons {
    margin-left: 5px !important;
    background-color: honeydew;
    border-radius: 50%;
    border: 3px solid white;
  } */

  /* .ratingicons1image {
    margin-left: 5px !important;
    background-color: honeydew;
    border-radius: 50%;
    border: 3px solid white;
  }

  .ratingiconslastimage {
    margin-right: 5px !important;
    background-color: honeydew;
    border-radius: 50%;
    border: 3px solid white;
  } */
}

.dividerrule {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.dividerrule svg {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

@media screen and (max-width: 560px) {
  .ratingitemdetail .itemdetail {
    display: block !important;
    text-align: center;
  }

  .ratingitemflexbody {
    display: block !important;
  }

  .rate_relax2 {
    max-width: 100% !important;
    text-align: center;
  }
}

@media screen and (max-width: 590px) {
  .dividerrule svg {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
}

.btn_SpaceManage {
  margin-top: 84px;
}

.fixedRating {
  position: fixed;
}

.changeInHeight {
  min-height: auto !important;
  height: 100%;
}

/* ----Responsive Area---- */

@media screen and (max-width: 600px) {
  .ratingText1 p {
    left: 17px;
  }

  .ratingText2 p {
    right: 25px;
  }
}

@media screen and (max-width: 520px) {
  .RatingSession {
    margin: 0px 20px;
  }

  .star-svg {
    margin: auto;
  }
}

@media screen and (max-width: 554px) {
  .dividerrule svg {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .RatingItems {
    max-width: 514px;
    width: 100%;
  }

  .ratingText1 p {
    left: 68px;
  }

  .ratingText2 p {
    right: 80px;
  }
}

@media screen and (max-width: 500px) {
  .ratingText1 p {
    left: 39px;
    right: 225px;
  }

  .ratingText2 p {
    right: 47px;
  }
}

@media screen and (max-width: 450px) {
  .ratingText1 p {
    left: 16px;
    right: 213px;
  }

  .ratingText2 p {
    right: 25px;
  }
}

@media screen and (max-width: 450px) {
  .ratingText {
    display: none;
  }
}

@media screen and (max-width: 390px) {
  .dividerrule svg {
    margin-right: 6px !important;
    margin-left: 6px !important;
  }
}

@media screen and (max-width: 360px) {
  .dividerrule svg {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
}

.last_icon_rating {
  display: flex;
  flex-direction: column;
}

.hide-border svg {
  fill: rgb(41, 170, 227) !important;
  border: 0.1px solid rgb(41, 170, 227) !important;
}

.skipbutton_show{
display: block;
animation-name: example;
  animation-duration: 2s;
  position: relative;
}
.skipbutton_none{
  display: none;
}

@keyframes example {
  0%   { left:0px; top: 0px;}
  50%  { left:10px; top:0px;}
  100%  { left:0px; top:0px;}
  
}