* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  text-decoration: none;
}



span.error {
  color: red;
}

.error {
  color: red;
}

body {
  background-image: url("../../../Assets/Images/backgroungbody.png");
  background-repeat: no-repeat;
  background-repeat-y: repeat;
  background-size: cover;
  background-position: 50%;
  min-height: 100% !important;
  background-attachment: fixed;
  font-family: "Roboto", sans-serif !important;
}

.signinlogofirst {
  margin-top: 40px;
  position: relative;
}

.signinlogofirst img {
  width: 100%;
}

.signinlogofirst button {
  border: none;
  background: none;
}

.containermain {
  padding-top: 10px;
}

.loginpagediv {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 40px;
}

.loginpagediv>div {
  margin-right: 10px;
}

/* done*/

.formcontainer {
  width: 100%;
  max-width: 100%;
}

.formbody {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 15px;
  max-width: 645px;
  min-height: 620px;
}

.forminnerbody {
  padding-top: 63px;
  max-width: 500px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.logintitle {
  text-align: center;
  padding-top: 10px;
  color: white;
}

.logintitle p {
  font-size: 13px;
  font-weight: 500;
}

.emaillogobody {
  margin-top: 20px;
  display: flex;
  width: 100%;
  background: #1f252a;
  height: 45px;
  padding: 5px 20px;
  border-radius: 5px;
}

.emaillogoform {
  margin-right: 15px;
}

input {
  width: 100%;
  background: #1f252a !important;
  border: none;
  color: white;
}

.signinpasswordbody {
  margin-top: 20px;
  display: flex;
  width: 100%;
  background: #1f252a;
  height: 45px;
  padding: 5px 20px;
  align-items: center;
  border-radius: 5px;
}

.signinpasswordform {
  margin-right: 15px;
}

.forgotpassword {
  text-align: right !important;
  padding-top: 30px;
  line-height: 1px;
}

.forgotpassword a {
  color: #00b1ff !important;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
}

.btn {
  margin-top: 14px;
  display: initial !important;
  width: 100%;
  background: #29AAE3 !important;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 16px !important;
  line-height: 19px !important;
  font-weight: bold !important;
  font-variant: normal !important;
  font-style: normal !important;
  color: #000000 !important;
font-family: inherit !important;
}

.btn:focus {
  border: 1px solid grey !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}


.btn a {
  color: white !important;
  text-decoration: none;
  text-align: center;
}

.sociallogodiv {
  margin: 30px 0;
  display: flex !important;
  justify-content: space-around !important;
  width: 100% !important;
  align-items: center !important;
}

.sociallogodiv>div {
  margin: 0px 20px !important;
}

.sociallogoitem a img {
  width: 135px;
  height: 45px;
}

.signup {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.signup p {
  color: white !important;
}

.signup>div {
  margin: 5px;
  align-self: flex-start;
}



.divider_Box {
  margin: 20px 0;
  color: white;
}

.divide {
  overflow: visible;
  text-align: center;
  padding: 0;
  margin: 0px;
  border: none;
}

.divide::after {
  display: inline-block;
  position: relative;
  top: -13px;
  padding: 0 16px;
  background: black;
  color: white;
}

.arrowdisplayblock {
  display: block;
}

.arrowdisplaynone {
  display: none;
}

.popupnotshow {
  display: none;
}

.popupshow {
  display: block;
  max-width: 40%;
  width: 100%;
}

/* it's responsive work  */

@media only screen and (min-width: 310px) and (max-width: 992px) {
  .loginpagediv {
    display: block;
    width: 100%;
  }

  .container {
    left: 10px !important;
  }

  .formbody {
    width: 100%;
    background: black;
    border-radius: 15px;
    padding: 40px 20px;
    max-width: 645px;
  }

  .popupshow {
    display: flex;
    max-width: 100%;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 470px) {
  .sociallogodiv {
    display: block !important;
    justify-content: center !important;
    text-align: center !important;
  }

  .sociallogodiv>div {
    margin-top: 10px !important;
  }
}

@media only screen and (min-width:470px) and (max-width: 750px) {
  .sociallogodiv {
    display: flex !important;
    justify-content: center !important;
    text-align: center !important;
  }
}


.outline {
  border: 1px solid red !important;

}

.loader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  z-index: -1;
}

.loader img {
  width: 50px;
}


.btn-check:focus+.btn,
.btn:focus {
  outline: -webkit-focus-ring-color auto 1px !important;
  box-shadow: none !important;
}