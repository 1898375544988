.container.sessionPage {
    padding-top: 100px !important;
    height: 100% !important;
    margin-bottom: 100px !important;
}



.HistDetailConatin {
    max-width: 900px;
    margin: auto;
    width: 100%;
}

.HistDetailConatin h3 {
    text-decoration: none;
    color: #fff;
}

.HistDetailConatin a:hover {
    color: #fff;
}

.itemBody {
    background-color: rgba(0, 0, 0, .65);
    border-radius: 15px;
    min-height: 520px;
}

.histItemHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 830px;
    border-bottom: 1px solid rgba(185, 185, 187, .4196078431372549);
    margin: auto;
    padding: 35px 10px 22px 0px;
}


.ItemTitle {
    text-align: center;
}

.ItemTitle h2 {
    color: #fff;
    font-size: 32px;
    line-height: 26px;
}


.ItemTitle span {
    color: #29aae3;
    font-size: 17px;
    font-weight: 300;
    line-height: normal;
}


.ItemDetail {
    border-bottom: 1px solid rgba(185, 185, 187, .4196078431372549);
    max-width: 830px;
    margin: auto;
    padding: 45px 0px;
}

.row.ItemArea {
    max-width: 780px;
    margin: auto;
    padding-top: 10px;
}

.ItemDetail .col-3 {
    color: #fff;
    text-align: center;
}

.ItemDetail p {
    font-size: 15px;
    color: hsla(0, 0%, 100%, .6);
    font-weight: 500;
    font-weight: 300;
    margin-top: 4px;
}

.ItemDetail h5 {
    font-size: 18px;
    font-weight: 300;
    margin-top: 30px;
}

.btnArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 840px;
    margin: auto;
    padding: 15px 0px 100px 10px !important;
}

.btnArea .btn {
    display: flex;
    align-items: center;
}

.btnArea .btn-primary {
    background-color: #29aae3 !important;
    border: none;
    color: #fff !important;
    margin-right: 10px;
}

.btnArea .btn-primary:hover {
    background-color: #29aae3;
    border: none;
    box-shadow: none;
}

.btnArea .btn-primary:focus {
    color: #fff;
    background-color: #29aae3;
    border: none;
    box-shadow: none;
}

.btnArea .btn-primary:focus-visible {
    outline: none;
    box-shadow: none;
}

.btnArea .btn-primary:active {
    color: #fff;
    background-color: #29aae3;
    border: none;
    box-shadow: none;
}

.btnArea .btn-primary:active:focus {
    box-shadow: none;
}


.btnArea .btn-danger {
    background-color: #e02020 !important;
    border: none;
    margin-right: 10px;
}

.btnArea .btn-danger:hover {
    background-color: #e02020 !important;
    border: none;
    box-shadow: none;
}

.btnArea .btn-danger:focus {
    color: #fff;
    background-color: #e02020;
    border: none;
    box-shadow: none;
}

.btnArea .btn-danger:focus-visible {
    outline: none;
    box-shadow: none;
}

.btnArea .btn-danger:active {
    color: #fff !important;
    background-color: #e02020;
    border: none;
    box-shadow: none;
}

.btnArea .btn-danger:active:focus {
    box-shadow: none;
}

.btnArea img {
    width: 19px;
    margin-top: 4px;
}

.duplicate span {
    font-size: 17px;
    color: #fff !important;
    font-weight: 500;
    padding: 6px 20px !important;
}

.remove span {
    font-size: 17px;
    color: #fff !important;
    font-weight: 500;
    padding: 6px 11px !important;
}

.share img {
    width: 16px;
}


.share span {
    font-size: 17px;
    font-weight: 500;
    color: #fff !important;
    padding: 6px 57px !important;
}

.replay span {
    font-size: 17px;
    font-weight: 500;
    color: #fff !important;
    padding: 6px 53px !important;
}

/* .goalicontitle p {
    max-width: 99% !important;
} */

.manageGoal {
    /* text-align: center !important; */
    max-width: 47%;
    padding-left: 6px;
}

.noDataFound {
    color: #808387;
    text-align: center;
    padding: 0px 0px;
    margin-bottom: 0px;
}

/* ----Responsive Area---- */

@media screen and (max-width: 992px) {
    .btnArea {
        display: block;
        max-width: 600px;
        margin: auto;
        padding: 15px 10px 90px 10px !important;
        width: 100%;
    }

    .btnArea .btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
    }

    .fixing_icon {
        margin-left: 6px;
    }



}

@media screen and (max-width: 500px) {
    .btnArea {
        max-width: 330px;
        margin: auto;
        padding: 0px 15px;
    }

    .tutorialContainer {
        margin-top: 20px;
        margin-bottom: 50px;
    }

}

@media screen and (max-width: 619px) {
    .btnArea {
        max-width: 330px;
        margin: auto;
        padding: 0px 15px;
    }

    .confirm-box {
        width: 100%;
        z-index: 1000;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

}

.favourateDetailLoader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-height: 100vh;
    text-align: center;
    z-index: -1;
    padding-top: 50px;
}


.favourateDetailLoader img {
    width: 60%;
}

/* .favourateDetailLoader img {
    max-width: 174px;
    width: 100%
} */