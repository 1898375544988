.introWrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  padding-bottom: 100px;
  margin-right: 20px;
  margin-left: 20px;
}

.introVdoBox {
  width: 100%;
  max-width: 750px;
  background: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  margin: auto;
  position: relative;
  height: 650px;
}

.introTop {
  display: flex;
  flex-wrap: wrap;
  background: #010914;
  height: 75px;
  align-items: center;
  position: relative;
}

.introTop .backBtn {
  position: absolute;
  left: 20px;
}

.introTop .actionRow {
  position: absolute;
  right: 0;
  width: auto;
}

.backBtn img:hover {
  cursor: pointer;
}

.actionRow {
  text-align: right;
  padding: 23px 20px;
}

.actionRow button {
  display: inline-block;
  vertical-align: middle;
  margin-left: 35px;
  font-size: 22px;
  background: transparent;
  border: 0;
  color: #fff;
}

.like svg {
  fill: none;
  stroke: none;
}

.likeOn svg {
  fill: white;
  stroke: white;
}

.actionRow button img {
  width: 20px;
  height: 20px;
}

.endBtn {
  width: 100%;
  max-width: 750px;
  padding: 25px 0px 40px 0px;
  margin: auto;
}

.endBtn button {
  background: #29aae3;
  color: #fff;
  width: 100%;
  max-width: 750px;
  border-radius: 5px;
  height: 52px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.cares2 {
  z-index: 99999999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
  background: #fff8f89e;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.cares {
  display: none;
}

.shownone {
  display: none;
}

/* new work for intro */
.introWrappernew {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  min-height: 100vh;
}

.new_intro_box {
  margin-bottom: 10px;
  width: 100%;
  max-width: 675px;
  background: rgba(0, 0, 0, 0.9) 0% 0% no-repeat padding-box;
  min-height: 600px;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 75px;
}

.fixedIntro {
  width: 100%;
  max-width: 675px;
  background: #000000 0% 0% no-repeat padding-box;
  min-height: 600px;
  margin-right: 10px;
  margin-left: 10px;
}

.intro_head {
  margin-top: 11px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: -5px !important;
}

.intro_title_image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px 18px;
}
.intro_title_image div:last-of-type {
  margin-right: 18px;
}

@media screen and (max-width: 450px) {
  .intro_title_image {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
  .intro_head {
   align-items: baseline;
  }

}
/* .intro_title_image > div{
  margin-right: 18px;
} */


.intro_end_session_but {
  display: flex;
  justify-content: space-around;
  margin-top: 4px;
}

.intro_end_session_but button {
  width: 183px;
  height: 44px;
  background: #29aae3 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font: normal normal 500 16px/19px ;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.info_goal_body {
  max-width: 604px;
  min-height: 208px;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding-left: 42px;
  padding-right: 42px;
}

.info_pergoal_titles {
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.info_pergoal_titles h5 {
  font: normal normal 600 24px/53px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.about_per_goal {
  margin-top: 2px;
}

.about_per_goal p {
  font: normal normal 300 13px/18px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.open_goal_about {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
  background: #fff8f89e;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.open_goal_about_none {
  display: none;
}

.showFeedback {
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
  background: #fff8f89e;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  padding-top: 80px;
  overflow-y: auto;
}

.hideFeedback {
  display: none;
}

.nowork {
  pointer-events: none !important;
}