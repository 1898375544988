.ouutercontainerSupport {
  background-color: rgba(0, 0, 0, 0.9);
  max-width: 650px;
  width: 100%;
  margin: auto;
  padding: 30px 0px;
  border-radius: 10px;
  margin-bottom: 10px !important;
}

.SupportText {
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 500;
  margin-right: 170px;
}

.support_Header {
  display: flex;
  justify-content: space-between;
}

span.inviteBack_Icon {
  margin-left: -20px;
}

.innercontainerSupport {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: justify;
  width: 100%;
  padding: 0px 10px;
}

.innercontainerSupport input {
  padding: 11px;
  border-radius: 5px;
}

.innercontainerSupport textarea {
  width: 100%;
  border-radius: 10px;
  height: 150px;
  padding: 10px;
  background-color: #1f252a;
  outline: none;
  color: white;
  border: none;
}

.terminnerconatinerimage {
  padding: 0px 0px 0px 5px;
  cursor: pointer;
  z-index: 1;
}

.terminnerconatinerimage p:hover {
  cursor: pointer;
}

.innercontainerSupport .terminnercontainertext {
  max-width: 430px;
  width: 100%;
  color: white;
  margin: auto;
}

.innercontainerSupport .terminnercontainertext h2 {
  display: block;
  width: 100%;
  padding-top: 15px;
  font-size: 15px;
  font-weight: 300;
}

.innercontainerSupport button {
  color: black;
  font-size: 19px;
  font-weight: 500;
  padding: 8px 0px;
  height: 43px !important;
}

.spport_Submit_Btn {
  margin-top: 11px;
  width: 100%;
  background-color: #29aae3;
  max-width: 430px;
  text-align: center;
  border-radius: 5px;
}

.SubButton {
  max-width: 430px;
  width: 100%;
}

.errorSpace {
  margin-top: 0px;
}

.MessageColor {
  font-size: 14px;
  color: #03630f;
  margin: 0;
}

span.EmailError {
  color: red !important;
  font-size: 12px !important;
}

p.EmailError {
  color: red !important;
  font-size: 12px !important;
}

.terminnercontainertext input::placeholder {
  font-size: 16px;
  font-weight: 400;
}

.terminnercontainertext textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
}


@media screen and (max-width:500px) {
  span.inviteBack_Icon {
    margin-left: 0px;
  }
}