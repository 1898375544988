.shareWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
}



.shareWrappers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  min-height: 100vh;
}

.shareBox {
  background-color: #000;
  height: 250px;
  width: 495px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  border-radius: 10px;
  border: 2px solid #0f5776;
}


.shareBoxs {
  flex-direction: column;
  background-color: #000;
  position: relative;
  max-width: 900px;
  width: 100%;
  display: flex;
  border-radius: 10px;
}


.shareBoxs>div {
  margin-top: 25px;
  margin-bottom: 25px;
  padding-right: 50px;
  padding-left: 50px;
}

.shareBoxs .title h3 {
  color: white;

}

.shareBoxs .title p {
  color: white;

}

.playerwithtitle {
  align-items: center;
  display: flex;
}

.playerwithtitle>div {
  width: 100%;

}

.playerwithtitle .player {
  width: 100%;
  max-width: 40%;
  margin-right: 40px;
}

.playerwithtitle .title {
  max-width: 60%;
  width: 100%;
}

.sharboxtitle h3 {
  margin-right: 40px !important;
  color: white;
}

.sharboxtitle p {
  margin-right: 40px !important;
  color: white;
}

.shareBoxs button svg {
  fill: white;
  width: 25px;
}

.shareBoxs button {
  right: 20px;
}

.shareBox button img {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 25px;
}

.title p {
  color: gray !important;
}



.LinkedInIcon img {
  background-color: #fff;
}

.shareWrapper .shareBox h3 {
  width: 100%;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 20px;
  padding-bottom: 20px;
}

.shareIcons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  text-align: center;
  position: relative;
}

.shareIconss {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  text-align: center;
  position: relative;
}




.shareIconss>div {
  padding-right: 45px;
}



.shareBoxs .shareIconss button img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.shareBoxs .shareIconss button .facebookimage {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: white;
}




.shareBoxs .shareIconss span {
  width: 100%;
  margin-top: 5px;
  display: block;
  color: #fff;
}

.imagesharboxcross {
  text-align: right;
}

@media screen and (max-width:540px) {
  .shareIconss {
    display: block;

  }

  .playerwithtitle .title {
    max-width: 100%;
    width: 100%;
  }

  .shareIconss>div {
    padding-right: 0px;
  }

  .playerwithtitle .player {
    width: 100% !important;
    max-width: 100% !important;
  }

  .playerwithtitle {
    text-align: center;
    display: block;
  }
}