.ouutercontainerterm {
  background-color: rgba(0, 0, 0, 0.9);
  max-width: 762px;
  width: 100%;
  margin: auto;
  padding: 16px 0px;
  border-radius: 10px;
}

.innercontainerterm {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  max-width: 600px;
  padding: 0px 10px;
}

.terminnerconatinerimage {
  padding-left: 0px 0px 0px 5px;
}

.innercontainerterm .terminnercontainertext {
  width: 100%;
  color: white;
  max-height: 430px;
  overflow: scroll;
  padding: 0px 10px;
}

.innercontainerterm .terminnercontainertext::-webkit-scrollbar {
  width: 8px;
}

.innercontainerterm .terminnercontainertext::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #737272;
  border: 1px solid #000;
}

.innercontainerterm .terminnercontainertext::-webkit-scrollbar-corner {
  display: none;
}

.innercontainerterm .terminnercontainertext h2 {
  display: block;
  width: 100%;
  padding-bottom: 6px;
  font-size: 21px;
  padding-top: 14px;
}

.innercontainerterm .terminnercontainertext p {
  display: block;
  width: 100%;
  font-weight: 300;
  line-height: 1.4;
  color: #CFCECE;
  font-size: 15px;
}

.condition_Header {
  display: flex;
  padding: 19px 0px 40px 0px;
}

.condition_Header h2 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  font-family: Rubik;
  margin-left: 145px;
}

.terminnercontainertext span {
  color: red;
  font-weight: 400;
}

.terminnercontainertext li {
  list-style-type: disc;
}

.listSpace li {
  margin-left: 20px;
  list-style-type: circle !important;
}

.terminnercontainertext a {
  color: #54c5ff !important;
  font-weight: 400 !important;
}

.terminnercontainertext a:hover {
  color: #54c5ff !important;
}

.changeLoopList li {
  list-style-type: none;
  margin-left: 20px;
}

@media screen and (max-width:450px) {
  .condition_Header h2 {
    margin-left: 88px;
  }
}