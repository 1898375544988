.tutorialWrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}



.tutoHeader {
  display: flex;
  justify-content: center;
}

.tutorialContainer {
  max-width: 670px;
  width: 100%;
  min-height: 520px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 15px;
  padding: 40px 50px;
  overflow: hidden;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.tutorialBox {
  width: 445px;
  margin: 0 auto;
}

.tutoHeader .backBtn {
  position: absolute;
  left: 20px;
}

.tutorialTitle {
  font-size: 28px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.tutorialVideoPlayer .carousel-indicators {
  margin-bottom: 0px !important;
}

.tutorialLoader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: 100vh;
  text-align: center;
  z-index: -1;
}

.carousel-indicators [data-bs-target] {
  outline: none !important;

}

.loader_Tutorial {
  /* height: 480px; */
  padding-bottom: 268px;
}

span.tutorialLoader img {
  width: 60%;
}

.new_turo_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
}

.reactvideo {
  border-radius: 12px !important;
}

.new_tur_innerbody {
  max-width: 726px;
  min-height: 548px;
  opacity: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding-right: 132px;
  padding-left: 132px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
}

.new_turo_wrappers {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
}

.modal-header {
  /* background-color: #29AAE3 !important; */
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none !important;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}



.new_tur_innerbodys {
  max-width: 700px !important;
  opacity: 1;
  width: 100%;
  height: 100%;
  background: gray(0, 0, 0, 0.9);
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 20px;
}

.new_tur_innerbodys .carousel_tutorial {
  margin-top: 0px !important;
}

.new_tut_title {
  margin-top: 34px;
}

.new_tut_title h3 {
  text-align: left;
  font: normal normal 600 28px/53px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-align: center;
}

.skip_Tuto_button {
  margin-top: 100px;
}

.skip_Tuto_button {
  text-align: center;
  width: 100%;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .new_tur_innerbody {
    max-width: 747px;
    min-height: 580px;
    opacity: 1;
    width: 100%;
    height: 10%;
    background: rgba(0, 0, 0, 0.9);
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media only screen and (max-width: 665px) {
  .sessiontutorials .modal-dialog {
    max-width: 606px !important;
    margin: 0px 20px !important;
  }
}

.carousel-indicators {
  top: 300px !important;
}

.carousel-indicators button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
}

.carousel_tutorial {
  margin-top: 58px;
}

@media only screen and (min-width: 666px) {
  .sessiontutorials .modal-dialog {
    max-width: 630px !important;
  }
}


.modal-content {
  border: 1px solid #29AAE3 !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: black !important;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal.show .modal-dialog {
  /* background-color: #29AAE3 !important; */
}

.session_tutorials {
  border-radius: 8px !important;
  border: none !important;
  margin-left: 20px !important;
  display: initial !important;
  min-width: 105px;
  padding: 10px;
  background: #29AAE3 !important;
  height: 44px;
  justify-content: center;
  align-items: center;
  font: normal normal 500 18px/19px  !important;
  color: #000000 !important;
}

.session_tutorials:focus {
  border: none !important;
}

.session_tutorials:active {
  border: none !important;
}