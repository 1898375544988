* {
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

.emailVerficationbody {
  margin: 120px auto;
}

.emailVerificationConatin {
  max-width: 600px;
  margin: auto;
}

.emailVerificationbody {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
  min-height: 480px;
  position: relative;
}

.emailverificationlogo {
  max-width: 663px;
  width: 100%;
}

.emailverificationlogo button img {
  position: absolute;
  top: 25px;
  left: 10px;
  bottom: 10px;
}

.emailVerificationbody .firstp {
  padding-bottom: 10px;
  color: gray;
  text-align: center;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
}

.emailVerificationbody h2 {
  padding-top: 50px;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 27px;
}

form.Form_Side {
  max-width: 460px;
  margin: auto;
  padding: 0px 10px;
}

form .EmailVerifyField input {
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}

form .EmailVerifyField span .text-primary {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.emailMessageColor {
  color: red !important;
}

.new_work_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.log_out_color {
  color: #4cabe3;
}