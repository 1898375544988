i {
  color: none;
}

.Algorithminnerbody {
  flex-wrap: wrap !important;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.topheadertitle {
  color: #ffffff;
  font: normal normal 500 24px/40px Rubik;
  margin-top: 15px;
  text-align: center;
}

.videotraildertitle {
  font: normal normal normal 15px/40px Rubik;
  color: #29aae3;
  padding-left: 10px;
}

.Algorithmformbody {
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 10px;
  max-width: 607px !important;
  min-height: 382px !important;
  border-radius: 10px;
  padding: 15px 15px 5px 15px;
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  width: 100%;
  background-color: #000000;
}

.fixedAlgo {
  max-width: 607px !important;
  min-height: 747px !important;
  border-radius: 10px;
  padding: 15px 15px 5px 15px;
  margin: auto;
  width: 100%;
  background-color: #000000;
  position: fixed;
}

.myform {
  margin-top: 26px;
}

.inputupperbody {
  border-radius: 10px;
  min-height: 128px;
  height: 100%;
  margin-right: 30px;
  max-width: 228px;
  min-width: 228px;
  width: 100%;
  padding: 10px 10px 10px 10px !important;
  margin-left: 30px;
  border: 3px solid #393636 !important;
}

.inputupperbody2 {
  height: 100%;
  border-radius: 10px;
  margin-right: 30px;
  display: inline-block;
  min-height: 120px;
  padding: 10px 10px 10px 10px !important;
  margin-left: 30px;
  border: 3px solid #393636 !important;
}

.firstpackagetitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.inputfieldfirst {
  opacity: 0;
}

.firstpackagesubtitle {
  cursor: pointer;
  flex-wrap: wrap !important;
  /* width: 100%;
  display: flex; */
}

.divider_Boxs {
  padding-top: 20px;
  margin: 16px 30px;
  color: white;
}

.divider {
  overflow: visible;
  text-align: center;
  padding: 0;
  margin: 0px;
  border: none;
  opacity: inherit;
  background-color: gray !important;
}

.divider::after {
  content: "Add-Ons";
  display: inline-block;
  position: relative;
  top: -18px;
  padding: 0 16px;
  background: rgb(0 0 0);
  font: normal normal normal 16px/40px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.Featuredtitle {
  white-space: nowrap;
  margin-right: 10px;
}

.secondinputarea {
  border: 3px solid #393636 !important;
}

.inputupperbody h3 {
  color: #ffffff !important;
  font: normal normal normal 18px/20px Rubik !important;
}

.inputupperbody2 h3 {
  color: #ffffff !important;
  font: normal normal normal 18px/20px Rubik !important;
}

.inputupperbody .packagevalue h3 {
  color: white !important;
}

.inputupperbody2 .packagevalue h3 {
  color: white !important;
}

.firstpackagetitle h6 {
  font: italic normal normal 9px/40px Rubik !important;
  color: #ffffff !important;
}

.toptitlepahra {
  margin-top: -8px !important;
}

/* .Toptitle{
  margin-left:-11px !important;
} */
.h6title {
  margin-left: 30px !important;
}

.packagevalues {
  text-align: left !important;
  font: normal normal 400 23px/30px Rubik !important;
  color: #ffffff !important;
  opacity: 1;
  margin-left: 14px;
}

.Featured {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
}

/* .Featured > div {
  margin-right: 27px;
} */

.h6title p {
  margin-top: -8px !important;
  color: white !important;
}

.firstpackagetitle p {
  font: italic normal normal 9px/15px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.lasttitle {
  margin-top: 12px;
  margin-bottom: 0px;
  margin-left: -9px;
  margin-right: -8px;
  text-align: center;
  hyphens: auto;
  color: #ffffff;
  font: normal normal 300 9px/14px Rubik !important;
}

/* .Featured div:not(:first-child) {
  margin-left: -19px;
} */

.lasttitle3 {
  font-weight: 300;
  font-size: 9px !important;
  color: #c5c9cd;
  text-align: left;
  margin-bottom: 0px !important;
}

.lasttitlefirstinput {
  color: #ffffff;
  text-align: center;
  font-size: 14px !important;
  font: normal normal normal 10px/15px Rubik;
}

.lasttitlesecondinput {
  margin-top: 0px;
  text-align: center;
  overflow: hidden;
  font: normal normal 300 9px/14px Rubik !important;
  letter-spacing: 0px;
  color: #ffffff !important;
  margin-bottom: 0px;
}

.flexbodysecondinput {
  margin-top: 36px;
  flex-wrap: wrap !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Alogorithbutton {
  margin-top: 45px;
  margin-bottom: 28px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Alogorithbutton button {
  border-radius: 5px;
  max-width: 181px;
  width: 100%;
  min-height: 44px;
  background: #29aae3 0% 0% no-repeat padding-box;
  color: #000 !important;
}

.Purchasebutton button {
  filter: brightness(50%);
}

.inputfieldfirst:checked ~ .geekmark {
  background-color: #29aae3 !important;
  border: 1px solid #29aae3 !important;
  color: black;
  cursor: alias !important;
}

.geekmark {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid white !important;
}

.labels .inputfieldfirst:checked ~ .geekmark:after {
  display: block;
}

.labels .geekmark:after {
  left: 6px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid rgb(7, 6, 6);
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
}

.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}

.activework {
  border: 3px solid #29aae3 !important;
}

.activework2 {
  border: 3px solid #393636 !important;
}

.firstpackagesubtitle span {
  font-size: 10px !important;
  color: #29aae3;
}

.firstpackagesubtitle div:not(:first-child) {
  color: #29aae3 !important;
}

.packagefee p {
  margin-top: -9px !important;
  text-align: center !important;
}

.newplantitle p {
  margin-top: -9px !important;
}

.Alogorithbutton span {
  color: #000000 !important;
  font: normal normal 600 14px/22px Rubik !important;
}

.Featured .Featuredtitle p {
  font: normal normal 300 9px/14px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  /* .headercontainer{
    display: block;
  } */
  /* .headercontainer > div {
    margin-right: 0px;
    margin-left: 0px;
} */
  .firstpackagetitle {
    display: block;
  }

  .h6title {
    margin-left: 0px !important;
  }

  .firstpackagetitle > div {
    margin-top: 20px !important;
  }

  .packagefee p {
    margin-top: -9px !important;
    text-align: left !important;
  }

  .packagevalues {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 630px) {
  .responsivetim {
    margin-top: 20px;
  }

  .payment_breath_logo:first-child div {
    max-width: 80px;
    overflow: hidden;
  }
}

.prmotion_video {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.prmotion_video_innerbody {
  border-radius: 8px;
  padding: 17px 30px 30px;
  max-width: 607px;
  background-color: #000;
  min-height: 441px;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.video_title_body {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.video_title_body h4 {
  color: #ffffff;
  font: normal normal 500 24px/0px Rubik;
  text-align: center;
}

.card_show_upper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}

.payment_body {
  max-width: 600px;
  min-height: 275px;
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.payment_exp_cvc_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.payment_exp_cvc_flex > div {
  margin-left: 5px !important;
}

.payment_exp_flex {
  max-width: 250px;
  width: 100%;
}

.payment_cvc_flex {
  max-width: 250px;
  width: 100%;
}

.subscribe_title {
  text-align: center;
  width: 100%;
  max-width: 400px;
}

.payment_breath_logo {
  align-items: center;
  display: flex;
  background-color: #1c1b1b;
  height: 80px;
  margin-bottom: 20px;
  border-radius: 8px;
  justify-content: space-between;
}

.payment_breath_logo img {
  height: 50px;
  margin-left: 10px;
}

.payment_body .DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.payment_body .DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.payment_body.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.payment_body .Demo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}

.payment_body label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.payment_body button {
  font: normal normal 500 16px/19px Rubik;
  border: 1px solid #29aae3;
  text-align: center;
  height: 44px;
  width: 123px;
  color: #000000;
  border-radius: 5px;
  text-transform: none;
  background: #29aae3 0% 0% no-repeat padding-box;
  text-decoration: none;
}

.payment_body input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  width: 100%;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 5px 5px 5px,
    rgba(0, 0, 0, 0.0196078) 5px 5px 5px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.payment_body input::placeholder {
  color: #aab7c4;
}

.payment_body input:focus,
.payment_body .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 5px 5px 5px,
    rgba(0, 0, 0, 0.0784314) 5px 5px 5px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.payment_body .StripeElement.IdealBankElement,
.payment_body .StripeElement.FpxBankElement,
.payment_body .StripeElement.PaymentRequestButton {
  padding: 0;
}

.payment_body .StripeElement.PaymentRequestButton {
  height: 40px;
}

.payment_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* bootstrap desgin */

.rightside {
  background-color: #ffffff;
  width: 35rem;
  padding: 1rem 2rem 3rem 3rem;
  border-radius: 10px;
}

.expcvv_text .expcvv_text {
  display: block;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0.8rem 0;
}

.inputbox {
  background-color: white !important;
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-bottom: 1.5px solid #ccc;
  margin-bottom: 1rem;
  border-radius: 2px;
  font-family: "Roboto", sans-serif;
  color: #615a5a;
  font-size: 1.1rem;
  font-weight: 500;
  outline: none;
}

.inputbox[type="password"][type="date"][type="number"][type="text"]::placeholder {
  color: black !important;
}

.inputbox[type="date"] {
  color: black;
}

.inputbox[type="password"] {
  color: black;
}

.inputbox[type="number"] {
  color: black;
}

.inputbox[type="text"] {
  color: black;
}

.expcvv {
  display: flex;
  justify-content: space-between;
  padding-top: 0.6rem;
}

.expcvv_text {
  padding-right: 1rem;
}

.expcvv_text2 {
  padding: 0 1rem;
}

.button_payment {
  background: linear-gradient(135deg, #753370 0%, #298096 100%);
  padding: 15px;
  border: none;
  border-radius: 50px;
  color: white;
  font-weight: 400;
  font-size: 1.2rem;
  margin-top: 10px;
  width: 100%;
  letter-spacing: 0.11rem;
  outline: none;
}

.button:hover {
  transform: scale(1.05) translateY(-3px);
  box-shadow: 3px 3px 6px #38373785;
}

[type="submit"] {
  -webkit-appearance: su;
}
/* new work logout */

.redirecthome button {
  border-radius: 5px;
  min-width: 120px;
  width: 100%;
  min-height: 44px;
  background: #29aae3 0 0 no-repeat padding-box;
  color: #000 !important;

  /* border-radius: 5px !important;
    padding: 0px 20px;
    width: 100%;
    max-width: 120px !important;
    /* min-height: 44px !important;
    max-height: 44px !important; */
  /* background: #29AAE3 0% 0% no-repeat padding-box; */
  /* color: #29AAE3 !important;  */
}
/* .redirecthome{
  margin-right: 0px !important;
} */

.accessCodeInput {
  align-items: center;
  margin-top: 20px;
  display: flex;
  width: 100%;
  background: #1f252a;
  height: 45px;
  padding: 5px 20px;
  border-radius: 5px;
}

.accessCodeBody {
  max-width: 540px;
  width: 100%;
  padding: 0px 80px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 40px;
  /* border-bottom: 1px solid #ababab; */
  margin-bottom: 40px;
}
