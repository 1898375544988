.vdoWrapper {
  display: flex;
  justify-content: center;
  background-color: rgb(0 0 0 / 79%);
  padding: 120px 0px;
}

.vdoBox {
  background-color: black;
  padding: 15px;
  width: auto;
  height: auto;
  position: relative;
}

.vdoBox a {
  display: flex;
  justify-content: end;
}

.vdoBox a img {
  width: 28px;
  height: 28px;
}

.closebtn {
  position: absolute;
}
