/* .SettingBody {
  padding-top:100px;
  height: 100%;
  margin-bottom:50px;
} */

.Setting_Contain {
  max-width: 840px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 16px;
  min-height: 620px;
}

.Setting_Title {
  color: white;
  text-align: center;
}

.settingArea {
  max-width: 880px;
  width: 100%;
}

.Setting_Title h3 {
  font-size: 28px;
  font-weight: 500;
  padding-top: 45px;
}

.Setting_Contain .row {
  display: flex;
  color: white;
  justify-content: center;
  padding-top: 28px;
  padding-bottom: 28px;
}

.side_Border {
  border-right: 1px solid rgba(185, 185, 187, 0.6705882352941176);
}

.Account_Area {
  padding-left: 38px;
  padding-right: 20px;
}

.Email_Area p {
  font-size: 13px;
  font-weight: 400;
  color: #a0a0a0;
}

.Email_Field {
  display: flex;
  width: 100%;
  background: #1f252a;
  height: 45px;
  padding: 5px 20px;
  align-items: center;
  border-radius: 6px;
}

.Email_Field input {
  background-color: transparent !important;
  color: #fff !important;
  width: 100%;
  border: none;
  text-decoration: none;
}

.Email_Field input:focus-visible {
  outline: none;
}

.row .title h3 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 25px;
}

.Community_Area {
  padding-left: 24px;
}

.SocialMedia {
  display: flex;
  margin: 5px;
  max-width: 50px;
}

.SocialMedia img {
  width: 18px;
  height: 25px;
  margin-right: 20px;
}

.SocialMedia p {
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

.SocialMedia a {
  text-decoration: none;
  color: white;
}

.SocialMedia a:hover {
  color: white !important;
}

.clr_del_Btn {
  display: flex;
  justify-content: space-between;
}

.ClearBtn {
  width: calc(69% - 20px);
}

.DeleteBtn {
  width: calc(69% - 20px);
}

.clr_del_Btn .btn-danger {
  background-color: #e02020 !important;
  height: auto;
  padding: 11px;
  width: 80%;
  font-size: 18px !important;
}

.clr_del_Btn .btn-primary {
  background-color: transparent !important;
  border: 2px solid #29aae3 !important;
  color: #29aae3 !important;
  height: auto;
  padding: 10px 24px;
  width: 85%;
  font-size: 18px !important;
}

.MyData {
  margin-top: 20px;
}

.clr_del_Btn .btn-primary:hover {
  background-color: transparent;
  border: 2px solid #29aae3;
  color: #29aae3;
}

.clr_del_Btn .btn-danger:hover {
  background-color: #e02020;
  border-color: none;
}

.clr_del_Btn .btn-danger a {
  text-decoration: none;
  color: #fff;
}

.clr_del_Btn .btn-danger:focus {
  color: #fff;
  background-color: #e02020;
  border-color: none;
  box-shadow: none;
}

.clr_del_Btn .btn-danger:active:focus {
  box-shadow: none;
}

.clr_del_Btn .btn-danger:active {
  color: #fff;
  background-color: #e02020;
  border-color: none;
  box-shadow: none;
}

.clr_del_Btn .btn-primary:focus {
  color: #29aae3;
  background-color: transparent;
  border-color: #29aae3;
  box-shadow: none;
}

.clr_del_Btn .btn-primary:active:focus {
  box-shadow: none;
}

.calculator {
  margin-top: 70px;
  margin-bottom: 50px;
}

.calculator ul {
  padding-left: 0rem;
  font-size: 16px;
  font-weight: 300;
}

.Support_Links {
  display: flex;
  justify-content: space-evenly;
}

.Support_Links li {
  margin-right: 10px;
}

.Support_Links a {
  margin: 0;
  padding: 0;
  left: 0;
}

.care {
  display: none;
}

/* ----Responsive Area---- */

/* @media screen and (max-width: 999px) {
  .clr_del_Btn {
    display: flex;
    text-align: center;
    justify-content: space-evenly;
  }
} */

@media screen and (max-width: 417px) {
  .clr_del_Btn {
    display: block;
    text-align: center;
    line-height: 65px;
    margin: auto;
    width: calc(100% - 50px);
  }

  .clr_del_Btn .btn-danger {
    margin: auto;
    height: 55px;
    width: 100%;
  }

  .clr_del_Btn .btn-primary {
    margin: auto;
    height: 55px;
    width: 100%;
  }

  .ClearBtn {
    width: calc(100% - 20px);
  }

  .DeleteBtn {
    width: calc(100% - 20px);
  }

  .Support_Links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.alert-success1 {
  color: #0f5132 !important;
}

@media screen and (max-width: 1150px) {}

@media screen and (max-width: 992px) {
  .Community_Area {
    padding-top: 30px;
  }

  .side_Border {
    border: none;
  }

  /* .Setting_Contain {
    min-height: 770px;
    margin-bottom: 100px;
} */
}

/* myaccount setting css */
.AccountSettingbody {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.AccountSettingbody .AccountSettingInnerbody {
  margin: 5px;
  margin-bottom: 100px;
  max-width: 762px;
  min-height: 600px;
  background: #060c0e 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding-right: 214px;
  padding-left: 186px;
}

.AccountSettingbody .AccountSettingInnerbody .Accountsettingformbody .Accountsettingtitle {
  margin-top: 33px;
  display: flex;
  width: 100%;
}

.accountsettingheading {
  text-align: left;
  font: normal normal normal 12px/22px Rubik;
  letter-spacing: 0px;
  color: rgba(168, 164, 164, 1);
  opacity: 1;
  margin-bottom: 0px !important;
}

.accountsettingabout {
  text-align: left;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  margin-bottom: 0px !important;
  margin-top: 4px;
}

.accountsettingheadgap {
  margin-top: 22px;
}

.accountsettingnewbu button {
  width: 100%;
  height: 100%;
  max-width: 362px;
  min-height: 44px;
  background: rgba(41, 170, 227, 1) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: left;
  font: normal normal 500 16px/19px ;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 1);
  opacity: 1;
  text-align: center;
}

/* newsettingwork */
.newsettingbody {
  margin: 5px;
  max-width: 762px;
  min-height: 651px;
  background: #060c0e;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 100%;
  /* padding-right: 186px;
  padding-left: 186px; */
}

.newsettingbody .settingtitle {
  text-align: center;
  font: normal normal 500 24px/28px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 38px;
}

.newsettingbutton {
  width: 100%;
  max-width: 486px;
  margin: auto;
  padding: 0px 47px;
}

.newsettingbutton button {
  width: 100%;
  min-height: 43px;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

.newsettingsvg img svg {
  width: 20px;
  height: 20px;
}

.settingtitleper p {
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px !important;
}

.costumeridname p {
  font: normal normal normal 14px/17px Rubik;
  letter-spacing: 0px;
  color: #a8a4a4;
  text-align: center;
}

.costumeridname {
  margin-top: 53px;
}

.newsetsuppli {
  max-width: 486px;
  width: 100%;
  margin: auto;
}

.new_Support_Links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px;
  margin-bottom: 0px;
}

.new_Support_Links_Title {
  font: normal normal normal 14px/17px Rubik;
  white-space: nowrap;
  letter-spacing: 0px;
  color: #fffcfc;
  opacity: 1;
  margin-bottom: 0px;
}

.new_Support_Links>li {
  margin: 3px !important;
}

@media screen and (max-width: 750px) {
  .newsettingbody {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}

@media screen and (max-width: 700px) {
  .new_Support_Links_Title {
    white-space: unset !important;
  }
}

@media screen and (max-width: 500px) {
  .AccountSettingbody .AccountSettingInnerbody .Accountsettingformbody {
    margin-left: 10px !important;
  }

  .responsivenonshow {
    display: none;
  }

  .new_Support_Links {
    display: flex;
    text-align: center;
  }
}

@media screen and (max-width: 754px) {
  .AccountSettingbody .AccountSettingInnerbody {
    padding-right: 100px;
    padding-left: 100px;
  }
}

@media screen and (max-width: 473px) {
  .AccountSettingbody .AccountSettingInnerbody {
    padding-right: 30px;
    padding-left: 30px;
  }
}

/* EditAccountSettingbody */
.EditAccountSettingbody {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 100px;
}

.EditAcc_Set_inn_body {
  margin: 5px;
  max-width: 762px;
  min-height: 557px;
  background: rgba(6, 12, 14, 1) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding: 0px 185px 0px 185px;
}

.Edit_acc_title {
  margin-top: 40px;
  display: flex;
}

.Edit_acc_title h5 {
  text-align: left;
  font: normal normal 500 24px/28px Rubik;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  margin-left: 100px;
}

.edit_acc_form {
  margin-top: 20px;
}

.edit-acc-label {
  text-align: left;
  font: normal normal normal 12px/22px Rubik;
  letter-spacing: 0px;
  color: #a8a4a4;
  opacity: 1;
  width: 100%;
  margin-top: 9px;
}

.edit_acc_form input {
  width: 100%;
  min-height: 43px;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  background-color: transparent !important;
  text-align: left;
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 0px 20px;
}

.edit_acc_form {
  width: 100%;
}

input[type="text"]:focus {
  border: 1px solid transparent;
}

.edit_acc_button_body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

p.topSpace {
  padding-bottom: 32px;
  margin: 0px;
}

p.topSpace img {
  width: 75px;
}

.edit_acc_cancel_button button {
  max-width: 191px;
  min-height: 44px;
  border: 1px solid #29aae3;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  text-align: center;
  height: 100%;
  font: normal normal 400 16px/19px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.edit_acc_cancel_button {
  width: 100%;
}

.edit_acc_sacg_button {
  width: 100%;
  text-align: right;
}

.edit_acc_sacg_button button {
  max-width: 183px;
  min-height: 44px;
  border: 1px solid #29aae3;
  border-radius: 5px;
  opacity: 1;
  width: 100%;
  text-align: center;
  background: #29aae3 0% 0% no-repeat padding-box;
  height: 100%;
  font: normal normal 500 16px/19px ;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
  max-width: 111px !important;
  min-height: 43px !important;
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #707070 !important;
  border-radius: 5px !important;
}

.PhoneInputCountryIcon--border {
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
  max-width: 36px !important;
  min-height: 16px !important;
  width: 100% !important;
  height: 100% !important;
  margin-left: 10px;
}

.PhoneInputCountrySelectArrow {
  margin-left: 46px !important;
  max-width: 7px !important;
  min-height: 4px !important;
  width: 100% !important;
  height: 100% !important;
}

.form-select {
  border: 1px solid #707070 !important;
  width: 100% !important;
  min-height: 43px !important;
  background-color: transparent !important;
  color: #ffffff !important;
  font: normal normal normal 14px/22px Rubik !important;
  letter-spacing: 0px;
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='white' stroke='%white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
  opacity: 1;
}


.form-select:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.form-select svg {
  fill: white;
}

.option {
  background-color: black !important;
}

.optionselect {
  color: #ffffff !important;
  background-color: rgba(6, 12, 14, 1) !important;
}

.form-control {
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  left: -54 !important;
  background-color: transparent !important;
  border: 1px solid #707070 !important;
  border-radius: 5px !important;
}

.react-tel-input .flag-dropdown:hover {
  background-color: transparent !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.react-tel-input .form-control {
  padding-left: 15px !important;
  width: 78% !important;
  margin-left: 85px !important;
  border: 1px solid #707070 !important;
  border-radius: 5px !important;
}

.form-control:focus {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.react-tel-input .selected-flag {
  width: 70px !important;
  padding-left: 15px !important;
}

.react-tel-input {
  text-align: -webkit-right !important;
}

.react-tel-input .country-list {
  text-align: left !important;
  width: 200px !important;
  background: #060C0E !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

.react-tel-input .selected-flag .arrow {
  width: 8px !important;
  height: 0;
  border-left: 4px solid transparent !important;
  border-right: 4px solid transparent !important;
  border-top: 6px solid white !important;
  left: 22px !important;
}

.react-tel-input .selected-flag .arrow.up {
  border-top: none !important;
  border-bottom: 6px solid white !important;
}



@media screen and (max-width: 768px) {
  .EditAcc_Set_inn_body {
    padding: 0px 100px;
  }
}

@media screen and (max-width: 600px) {
  .EditAcc_Set_inn_body {
    padding: 0px 40px;
  }

  .edit_acc_button_body {
    flex-wrap: wrap !important;
    text-align: center;
  }

  .edit_acc_button_body>div {
    margin-top: 10px;
  }

  .EditAcc_Set_inn_body {
    padding-bottom: 10px;
  }

  .edit_acc_sacg_button {
    width: 100%;
    text-align: center;
  }
}