.PolicyContain {
    background-color: #fff;
    padding-bottom: 20px;
    padding-top: 100px;
}

.PolicyContain p {
    font-size: 15px;
    color: #626262;
    margin: 0px;
}

.PolicyContain h4 {
    padding-top: 20px;
    padding-bottom: 8px;
}