.password_Container {
    max-width: 762px;
    min-height: 557px;
    background: rgba(6, 12, 14, 1) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 10px !important;
}

.changePass_Header {
    padding-top: 34px;
    display: flex;
    align-items: baseline;
}

.changePass_Body {
    max-width: 390px;
    width: 100%;
    margin: auto;
    padding: 0px 6px;
}

.changePass_Title {
    margin-left: 90px;
}

.changePass_Title h5 {
    text-align: left;
    font: normal normal medium 24px/28px Rubik !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 24px;
    line-height: 28px;
    font-family: Rubik;
}

.changePass_Field {
    padding-top: 40px;
}

.changePass_Field label {
    text-align: left;
    letter-spacing: 0px;
    color: #A8A4A4;
    opacity: 1;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Rubik';
    line-height: 24px;
}

.old_Pass_Body input {
    height: 42px;
    border-radius: 5px;
    width: 100%;
    opacity: 1;
    border: 1px solid #707070;
    background-color: #1f252a !important;
    padding: 0px 22px;
}

.changePass_Field input::placeholder {
    color: #8f8c8c;
}

.password_Input2 {
    border-radius: 5px;
    width: 100%;
    opacity: 1;
    border: 1px solid #707070;
    background-color: #1f252a !important;
    padding: 0px 22px;
    display: flex;
    align-items: center;
    height: 42px;
}

.pass_input_Field {
    margin-bottom: 20px;
}

.Update_button {
    background: #29AAE3 0% 0% no-repeat padding-box;
    border: 1px solid #29AAE3;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    /* height: 44px; */
}

.changePasswordError {
    margin: 94px 0px 0px 0px;
}

.Update_button button {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    padding: 8px 0px;
}

.hide_eye_Icon {
    display: none;
}

.changePass_Field input[type="text"]:focus {
    border: none !important;
}

.loadSet {
    padding: 0px;
}

.loadSet img {
    width: 50%;
}