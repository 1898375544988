.FavoriteContain {
    max-width: 670px;
    width: 100%;
    background-color: rgba(0, 0, 0, .9);
    margin: auto;
    border-radius: 14px;
    min-height: 620px;
    margin-bottom: 10px;
}

.Title_ClrBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 20px;
    padding-top: 35px;
}

.FavText {
    max-width: 50%;
    width: 100%;
    text-align: left;
}

.ClrBtn a {
    display: flex;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}

.ClrBtn a:hover {
    color: #fff;
}

.ClrBtn img {
    margin-right: 10px;
}

.Title h2 {
    color: #fff;
    font-size: 25px;
}

.MyFavList {
    max-width: 630px;
    width: 100%;
    margin: auto;
    padding-bottom: 39px;
    margin-top: 5px;
}

.HistList p {
    text-align: center;
    color: #929292;
    font-size: 17px;
    padding-top: 120px;
    font-weight: 300 !important;
}

.FavItem {
    background-color: #1f252a;
    border-radius: 6px;
    margin-bottom: 12px;
    width: 100%;
    max-width: 630px;
}

.HistIcon img {
    vertical-align: middle !important;
}

.FavItem a {
    width: 100%;
    max-width: 630px;
    text-decoration: none;
    display: flex !important;
    align-items: baseline !important;
    justify-content: space-between !important;
    /* margin: 0px 20px !important; */
    padding: 17px 20px !important;
}

.FavItem h3 {
    margin-bottom: 0px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: left;

}

.FavItem span {
    max-width: 50%;
    width: 100%;
    text-align: right;
    color: #a0a0a0;
    font-size: 12px;
    font-weight: 400;
}

.FavItem img {
    width: 16px;
}


.confirm-box {
    z-index: 999999999 !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 400px;
    left: 0px !important;
    right: 0px !important;
    margin: auto !important;
}

.confirm-box__content {
    z-index: 9999999999999999 !important;
    padding: 3em 1em !important;
    border-radius: 5px !important;
    width: 100% !important;
    max-width: 400px !important;
    height: 170px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    background: #010710 !important;
    color: #fff !important;
    text-align: center !important;
    border: 2px solid #355d6f !important;
}

.confirm-box__actions {
    display: flex !important;
    padding-top: 1em !important;
    justify-content: center !important;

}

.confirm-box__content>span {
    color: #fff !important;
}

.confirm-box__actions> :not(:last-child) {
    margin: 0, 1px !important;
    color: black !important;
}

.confirm-box__actions button {
    color: black !important;
    background: #29aae3 !important;
    outline: none !important;
    border: none !important;
    display: inline-block !important;
    padding: 6px 18px !important;
    margin-right: 10px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    cursor: pointer !important;
}

.favouriteLoader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-height: 100vh;
    text-align: center;
    z-index: -1;
}

span.favouriteLoader img {
    width: 60%;
}