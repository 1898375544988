.HistDetailConatin {
  max-width: 900px;
  margin: auto;
  width: 100%;
}

.HistDetailBody {
  margin-bottom: 40px;
  height: 100%;
}

.HistDetailConatin a {
  text-decoration: none;
  color: #fff;
}

.HistDetailConatin a:hover {
  color: #fff;
}

.itemBody {
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 15px;
  min-height: 520px;
}

.histItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 830px;
  border-bottom: 1px solid rgba(185, 185, 187, 0.4196078431372549);
  margin: auto;
  padding: 35px 10px 22px 0px;
}

.ItemTitle {
  text-align: center;
}

.ItemTitle h2 {
  color: #fff;
  font-size: 32px;
  line-height: 26px;
  text-transform: capitalize;
}

.ItemTitle span {
  color: #29aae3;
  font-size: 17px;
  font-weight: 300;
  line-height: normal;
}

.HeartIcon button {
  border: none;
  background: none;
}

.ItemDetail {
  border-bottom: 1px solid rgba(185, 185, 187, 0.4196078431372549);
  max-width: 830px;
  margin: auto;
  padding: 45px 0px;
}

.row.ItemArea {
  max-width: 780px;
  margin: auto;
  padding-top: 10px;
}

.ItemDetail .col-3 {
  color: #fff;
  text-align: center;
}

.ItemDetail p {
  font-size: 15px;
  color: hsla(0, 0%, 100%, 0.6);
  font-weight: 500;
  font-weight: 300;
  margin-top: 4px;
}

.ItemDetail h5 {
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;
}

.btnArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 840px;
  margin: auto;
  padding: 15px 0px 100px 10px !important;
}

.btnArea .btn {
  display: flex;
  align-items: center;
}

.btnArea .btn-primary {
  background-color: #29aae3 !important;
  border: none;
  height: 50px;
}

.btnArea .btn-primary:hover {
  background-color: #29aae3;
  border: none;
  box-shadow: none;
}

.btnArea .btn-primary:focus {
  color: #fff;
  background-color: #29aae3;
  border: none;
  box-shadow: none;
}

.btnArea .btn-primary:focus-visible {
  outline: none;
  box-shadow: none;
}

.btnArea .btn-primary:active {
  color: #fff;
  background-color: #29aae3;
  border: none;
  box-shadow: none;
}

.btnArea .btn-primary:active:focus {
  box-shadow: none;
}

.btnArea .btn-danger {
  background-color: #e02020 !important;
  border: none;
  height: 50px;
}

.btnArea .btn-danger:hover {
  background-color: #e02020 !important;
  border: none;
  box-shadow: none;
}

.btnArea .btn-danger:focus {
  color: #fff;
  background-color: #e02020;
  border: none;
  box-shadow: none;
}

.btnArea .btn-danger:focus-visible {
  outline: none;
  box-shadow: none;
}

.btnArea .btn-danger:active {
  color: #fff;
  background-color: #e02020;
  border: none;
  box-shadow: none;
}

.btnArea .btn-danger:active:focus {
  box-shadow: none;
}

.btnArea img {
  width: 19px;
  margin-top: 4px;
}

.duplicate span {
  font-size: 17px;
  font-weight: 500;
  padding: 6px 20px;
}

.remove span {
  font-size: 17px;
  font-weight: 500;
  padding: 6px 17px;
}

.share img {
  width: 16px;
}

.share span {
  font-size: 17px;
  font-weight: 500;
  padding: 6px 57px;
}

.replay span {
  font-size: 17px;
  font-weight: 500;
  padding: 6px 53px;
}

.like svg {
  fill: none;
  stroke: none;
}

.likeOn svg {
  fill: white;
  stroke: white;
}

/* ----Responsive Area---- */

@media screen and (max-width: 992px) {
  .btnArea {
    display: block;
    max-width: 600px;
    margin: auto;
    width: 100%;
  }

  .btnArea .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
  }
}

@media screen and (max-width: 500px) {
  .btnArea {
    max-width: 330px;
    margin: auto;
  }
}

.historyDetailLoader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: 100vh;
  text-align: center;
  z-index: 999;
  padding-top: 50px;
}

.historyDetailLoader img {
  width: 60%;
}

/* .historyDetailLoader img {
  max-width: 174px;
  width: 100%
} */

.clor {
  background-color: transparent;
}

/* svg #heart:hover {
  transition: width 1s, height 1s;
  width: 30px;
  height: 30px;
 } */

#heart-path:active {
  fill: white;
  animation: mymove 2s;
  position: relative;
}

@keyframes mymove {
  0% {
    transform: scale(1.5);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.5);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

/* newhistorydetailbody */

.newhistorydetailinnerbody {
  border-radius: 10px;
  max-width: 1067px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 0px 44px 44px 44px;
  margin-bottom: 10px;
}

.fixedHis {
  position: fixed;
  border-radius: 10px;
  max-width: 1067px;
  width: 100%;
  min-height: 707px;
  background-color: #000000;
  padding: 0px 44px 44px 44px;
}

.newhistorydetailuperbody {
  display: flex;
  justify-content: space-around;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
  position: sticky;
}


.newHisDetTitle {
  flex-wrap: wrap;
  margin-top: 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.newHisDetTitle>div {
  margin-top: 9px;
  margin-right: 10px;
}

.historylogo .backimagetitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.historylogo .backimagetitle>div {
  margin-left: 20px;
}

.historylogo .newhistoryexname h2 {
  font: normal normal 500 25px/29px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.historylogo .newhistoryexname p {
  font: normal normal normal 14px/17px Rubik;
  letter-spacing: 0px;
  color: #a8a4a4;
}

.newhistorydelete .newdelicon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.newhistorydelete>div {
  margin-left: 10px;
  width: 100%;
}

.newhistorydelete .newdelicon .deletetitle {
  font: normal normal normal 14px/17px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.delimageback {
  display: flex;
  justify-content: space-around;
  border: 1px solid red;
  background: red;
  height: 30px;
  border-radius: 50%;
  width: 30px;
}

.delimageback img {
  width: 20px !important;
}

.exerciseitemshow {
  margin-top: 37px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.exerciseitemshow>div {
  margin-top: 10px;
  margin: 3px;
}

.exercoseitembackground {
  display: flex;
  align-items: center;
  min-width: 171px;
  min-height: 96px;
  background: #1f252a;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.goalicontitle {
  margin-left: -3px;
}

.exercoseitembackground .newhistoryitem {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  align-items: center;
}

.newhistoryitem .goalicontitle p {
  font: normal normal 400 18px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0px !important;
}

.newhistoryitem .goalicontitle span {
  font: normal normal normal 12px/0px Rubik;
  letter-spacing: 0px;
  color: #a8a4a4;
  opacity: 1;
}

.feedbacknumber {
  margin-top: 47px;
}

.feedbacknumber p {
  font: normal normal 400 16px/19px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.feedbackhistory {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  margin-bottom: 1px;
}

.feedback_bottom_area {
  margin-bottom: 10px;
  margin-top: -6px
}

.feedbackhistory .feedbacktime p {
  font: normal normal normal 12px/5px Rubik;
  letter-spacing: 0px;
  color: #a8a4a4;
  opacity: 1;
}

.feedbackdelete {
  cursor: pointer;
}

.feedbackhistory .feedbackdelete span img {
  height: 15px;
  width: 15px;
}

.Deleteonefeedback {
  font: normal normal normal 12px/14px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.allfeedback {
  max-height: 280px;
}

.allfeedback .fulldiscription {
  font: normal normal normal 14px/22px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 0px;
  word-break: break-word;
}

.newhistfootersec {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 42px;
  align-items: flex-end;
}

.exercoseitembackgrounds {

  max-width: 196px ;


}


.newhistfootersec .button_replay {
  background: #29aae3 0% 0% no-repeat padding-box;
  border: 1px solid #29aae3;
  border-radius: 5px;
  opacity: 1;
  max-width: 204px;
  width: 100%;
  min-height: 44px;
  height: 100%;
  font: normal normal 500 16px/19px ;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  white-space: no-wrap !important;
}

.newhistfootersec button {
  background: transparent;
  border: 1px solid #29aae3;
  border-radius: 5px;
  max-width: 204px;
  min-height: 44px;
  font: normal normal 500 16px/19px ;
  letter-spacing: 0px;
  color: #ffffff;
  width: 100%;
  opacity: 1;
  height: 100%;
}



.footersecondpart {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footersecondpart>button {
  margin-left: 10px;
}

.modifyLoader img {
  width: 100% !important;
}

.modifyLoader {
  padding-top: 100px !important;
}

hr.dividers {
  height: 2px !important;
  background: #f6eeee;
}


@media screen and (max-width: 993px) {
  .response_bigtitle {
    max-width: 96px !important;
  }

  .item1block {
    flex: 33.33%;
  }

  .item2block {
    flex: 33.33%;
  }

  .item3block {
    flex: 33.33%;
  }

  /* .item4block {
    flex: 40%;
  } */

  .item5block {
    flex: 33.33%;
  }

  .responseextra {
    width: 100% !important;
    /* max-width: 50% !important; */
  }
  .exercoseitembackgrounds{
    width: 100% !important;
    max-width: 100%;
  }
}

@media screen and (max-width: 477px) {
  .item1block {
    flex: 100%;
  }

  .item2block {
    flex: 100%;
  }

  .item3block {
    flex: 100%;
  }

  .item4block {
    flex: 100%;
  }

  .item5block {
    flex: 100%;
  }

  .responseextra {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 622px) {
  .newhistfootersec {
    text-align: center !important;
    display: block !important;
    width: 100% !important;
  }

  .footersecondpart {
    text-align: center !important;
    display: block !important;
    width: 100% !important;
  }

  .footersecondpart>button {
    margin-top: 5px !important;
    margin-left: 0px !important;
  }


  .changesresponse {
    width: 100% !important;
    max-width: 100% !important;
  }

  .responsefooterwork2 {
    width: 100% !important;
    max-width: 100% !important;
  }

}

.hide_player {
  display: none;
}

.footersecondpart {
  justify-content: end !important;
}

.fixing_icon {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: rgb(53, 59, 63);
  padding: 15px
}

.scroll_cus_feedback {
  padding: 0px 5px 0px 0px;
  max-height: 191px;
  overflow: auto;
}