.FooterArea {
    text-align: center;
    background: black;
    color: #fff;
    font-size: 15px;
    padding: 20px 12px 4px 12px;
}

.FooterArea p a {
    color: #f1324f;
    text-decoration: none;
}