.calibrationIntroTop .calibrationActionRow {
  position: absolute;
  right: 0;
  width: auto;
}

.calibrationActionRow {
  text-align: right;
  padding: 23px 20px;
}

.calibrationActionRow button {
  display: inline-block;
  vertical-align: middle;
  margin-left: 35px;
  font-size: 22px;
  background: transparent;
  border: 0;
  color: #fff;
}

.calibrationLike svg {
  fill: none;
  stroke: none;
}

.calibrationLikeOn svg {
  fill: white;
  stroke: white;
}

.calibrationActionRow button img {
  width: 20px;
  height: 20px;
}

.calibrationEndBtn button {
  background: #29aae3;
  color: #fff;
  width: 100%;
  max-width: 750px;
  border-radius: 5px;
  height: 52px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.calibrationNewIntroBox {
  margin-top: 10px;
  border-radius: 13px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 675px;
  background: rgba(0, 0, 0, 0.9) 0% 0% no-repeat padding-box;
  min-height: 475px;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

.calibrationIntroEndSessionBut button.disabledButton {
  opacity: 0.5;
  cursor: not-allowed;
}

.calibrationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calibrationNewIntroBox {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calibrationVideoPlayer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.calibrationCompleteCalibrationButton {
  background-color: #29aae3;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.calibrationIntroEndSessionBut {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  padding-bottom: 25px;
}

.calibrationIntroHead {
  margin-top: 11px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: -5px !important;
}

.calibrationIntroTitleImage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px 18px;
}

.calibrationIntroTitleImage div:last-of-type {
  margin-right: 18px;
}

@media screen and (max-width: 450px) {
  .calibrationIntroTitleImage {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
  .calibrationIntroHead {
    align-items: baseline;
  }
}

.calibrationIntroEndSessionBut {
  margin-top: 4px;
}

.calibrationIntroEndSessionBut button {
  width: 120px;
  height: 44px;
  background: #29aae3 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font: normal normal 500 16px/19px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.calibrationSkipButton {
  position: relative;
  bottom: 112px;
  left: 225px;
  background-color: none;
  color: #ffffff;
  padding: 8px 16px;
  border: .5px solid white;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  max-width: 160px;
}

.calibrationInfoPergoalTitles {
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.calibrationInfoPergoalTitles h5 {
  font: normal normal 600 24px/53px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.calibrationAboutPerGoal {
  margin-top: 2px;
}

.calibrationAboutPerGoal p {
  font: normal normal 300 13px/18px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.calibrationOpenGoalAbout {
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
  background: #fff8f89e;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.calibrationOpenGoalAboutNone {
  display: none;
}

.calibrationShowFeedback {
  z-index: 999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto !important;
  background: #fff8f89e;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  padding-top: 80px;
  overflow-y: auto;
}

.calibrationHideFeedback {
  display: none;
}

.calibrationNowork {
  pointer-events: none !important;
}
