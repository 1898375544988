.care2 {
    z-index: 9999;
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto !important;
    justify-content: center;
    align-items: center;
    background: #0000009e;
}

.care {
    display: none;
}

.ConfirmContainer {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    border: 2px solid #355d6f !important;
    border-radius: 10px;
    margin: 0px 1px;
}

.innerContent {
    margin: 0 22px;
    padding-bottom: 25px;
    padding-top: 12px;
}


.innerContent h4 {
    font-size: 16px;
    text-align: center;
    padding: 10px 60px;
}

.inputArea label {
    font-size: 13px;
    color: #a0a0a0;
    font-weight: 400;
}

.inputField {
    padding: 8px 0px;
}

.inputField input {
    width: 100%;
    background: #1f252a;
    ;
    border: none;
    padding: 12px 5px;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
}

.inputField input:focus {
    outline: none;
}

.DelConfirmBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DelConfirmBtn .done {
    width: 45%;
    background: #FF0000 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    padding: 10px 0px;
    border: none;
    font-size: 19px;
    font-weight: 500;
    border-radius: 6px;
    height: 50px;
    margin-top: 0px;
}

.DelConfirmBtn .done:focus {
    outline: none;
    background: #FF0000 0% 0% no-repeat padding-box !important;
    box-shadow: none;
    border: none;
    color: #fff !important;
}

.DelConfirmBtn .done:hover {
    background: #FF0000 0% 0% no-repeat padding-box !important;
    color: #fff !important;
    border: none;
}

.DelConfirmBtn .done:active:focus {
    box-shadow: none;
    color: #fff !important;
}

.DelConfirmBtn .cancel {
    width: 45%;
    background-color: #29AAE3 !important;
    padding: 10px 0px;
    border: none;
    font-size: 19px;
    font-weight: 500;
    border-radius: 6px;
    height: 50px;
    margin-top: 0px;
    border: 1px solid #29AAE3;
    color: black !important;
}

.DelConfirmBtn .cancel:focus {
    outline: none;
    background-color: #29AAE3 !important;
    box-shadow: none;
    border: 1px solid #29AAE3;
    color: black !important;
}

.DelConfirmBtn .cancel:hover {
    background-color: #29AAE3 !important;
    color: black !important;
    border: 1px solid #29AAE3;
}

.DelConfirmBtn .cancel:active:focus {
    box-shadow: none;
    color: black !important;
    background-color: #29AAE3 !important;
}