* {
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

.logofirstsignup {
  top: 40px;
  width: 50px;
  height: auto;
  z-index: 999;
  position: relative;
}

.logofirstsignup img {
  width: 40;
}

.signupfullbodyflex {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  padding-bottom: 40px !important;
}

.singupfullbody {
  padding-bottom: 10px !important;
}

.signupupperbody {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  border-radius: 15px;
  max-width: 650px;
  padding-top: 40px;
  margin-right: auto;
  margin-left: auto;
  margin-right: 10px;
  margin-left: 10px;
}

.signupinnerbody {
  max-width: 460px;
  width: 100%;
  padding: 0px 10px;
  margin-right: auto;
  margin-left: auto;
}

.signuptext {
  text-align: center;
  color: white;
}

.signuphomebody {
  align-items: center;
  margin-top: 20px;
  display: flex;
  width: 100%;
  background: #1f252a;
  height: 45px;
  padding: 5px 20px;
  border-radius: 5px;
}

.forgotformimage {
  margin-right: 15px;
}

.btn-signUp {
  display: flex;
  margin-top: 20px;
  height: 50px;
  border-radius: 5px;
  width: 100%;
  font-size: 19px;
  background: #29aae3;
  align-items: center;
  justify-content: center;
  color: #fff !important;
}

.signuptext p {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 30px;
}

.heigtsCl {
  height: 100%;
}

.btn-signUp button {
  color: #dee2e7 !important;
}

.btn-signUp button {
  background: none;
  border: none;
}

.btn-signUp button a {
  text-decoration: none;
  color: #fff;
}

.signupArea {
  padding-bottom: 18px;
  display: flex;
  justify-content: center;
}

.signupArea p {
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  margin-right: 5px;
}

.signupArea a {
  color: #29aae3;
}

.signupArea a:hover {
  color: #29aae3;
}

.sociallogoitem a img {
  width: 135px;
  height: 45px;
}

.sociallogoitem {
  cursor: url(../../../Assets/Images/cursor.jpeg), auto;
}

.divider_Box {
  margin: 25px auto;
  color: white;
  padding-top: 15px;
}

.divide {
  overflow: visible;
  text-align: center;
  padding: 0;
  margin: 0px;
  border: none;
}

.divide::after {
  display: inline-block;
  position: relative;
  top: -13px;
  padding: 0 16px;
  background: #040c0d !important;
  color: white !important;
}

.buttonload {
  border: none;
  /* Remove borders */
  color: black;
  /* White text */
  padding: 12px 24px;
  /* Some padding */
  font-size: 16px;
  /* Set a font-size */
}

/* / Add a right margin to each icon / */

.fa {
  margin-left: -12px;
  margin-right: 8px;
}

.kep-login-facebook .metro {
  font-size: 0px !important;
}

.errorMessageColor {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

/* .kep-login-facebook.metro {
  border-radius: 5px !important;
  width: 135px;
  height: 45px;
}

.kep-login-facebook {
   font-weight:0 !important; 
  cursor: pointer;
  font-size:0px !important; 
  text-decoration: none;
  text-transform: none;
  transition: none !important;
  background-color: #4c69ba;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  padding: 0px;
} */

@media only screen and (min-width: 310px) and (max-width: 992px) {
  .signupfullbodyflex {
    padding-top: 50px;
    display: block;
    height: 100%;
    padding-bottom: 40px !important;
  }

  .signupupperbody {
    margin: auto;
  }
}

@media only screen and (min-width: 350px) and (max-width: 992px) {
  .container {
    left: 10px !important;
    max-width: 995px !important;
    width: 100% !important;
  }

  .ForgotConatin {
    display: block;
  }

  .ForgotConatin > div {
    margin: auto;
  }
}

.loaders img {
  width: 70px;
}

.sociallogoitem.facebookLogin img {
  width: 135px;
}

/* .facebookLogin {
    position: relative;
}

.facebookLogin:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("../../../Assets/Images/facebook.svg");
} */

.Featurdgoalbody {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

.Featurdgoalbody .Featureclaainnerbody {
  border-radius: 8px;
  padding: 17px 30px 30px 30px;
  max-width: 607px;
  background-color: #000000;
  min-height: 441px;
  height: 100%;
  width: 100%;
}

/* .imageclose{
  background: transparent url('img/Image 333.png') 0% 0% no-repeat padding-box;
opacity: 1;
} */

.Featurdgoalbody .Featureclaainnerbody .Featuretitlbody {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Featurdgoalbody .Featureclaainnerbody .Featuretitlbody .title h2 {
  font: normal normal 600 24px/53px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
}

.Featurdgoalbody .Featureclaainnerbody .Featuretitlbody .crossimage svg {
  fill: white;
}

.Featurgoalsubtitle {
  padding-right: 22px;
  min-height: 400px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Featurgoalsubtitle h3 {
  font-size: 19px;
  color: white;
}

.Featurgoalsubtitle p {
  text-align: justify;
  font-size: 12px !important;
  color: #b5afaf;
}

.backgrounone {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: #fff8f89e;
  max-height: 100vh;
  width: 100%;
  overflow-y: auto;
  z-index: 9999 !important;
  display: block;
}

.goalinformation {
  font: normal normal 300 13px/18px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

/* breathabout */

.inputfieldauto:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #1f252a inset !important;
  background-color: transparent !important;
  -webkit-text-fill-color: white !important;
  background-clip: content-box !important;
}

.inputfieldauto:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #1f252a inset !important;
  background-color: transparent !important;
  -webkit-text-fill-color: white !important;
  background-clip: content-box !important;
}

.inputfieldauto:-webkit-autofill:hover,
.inputfieldauto:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #1f252a inset !important;
  background-color: transparent !important;
  -webkit-text-fill-color: white !important;
  background-clip: content-box !important;
}

.terms-conditions {
  flex-direction: "row";
  display: flex;
  align-items: flex-start;
  margin-top: 35px;
  margin-bottom: 20px;
  color: #636363;
  font-weight: 300;
}

.terms-link {
  color: #29aae3;
  font-size: 14px;
  text-decoration: underline solid #29aae3 !important;
  font-weight: 400;
}

.terms-link:hover {
    color: #29aae3;
    font-weight: bold;
  }

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

/* Create a custom box */
.custom-checkbox .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #1f252a;
  border: 1px solid #1f252a;
  display: inline-block;
  vertical-align: middle;
}

.custom-checkbox .checkmark::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 2px;
    background-color: rgb(212, 204, 204);
    display: none;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  
  .custom-checkbox .checkmark::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 14px;
    background-color: rgb(212, 204, 204);
    display: none;
    transform: translate(-50%, -50%) rotate(45deg);
  }

.custom-checkbox input[type="checkbox"]:checked + .checkmark::after,
.custom-checkbox input[type="checkbox"]:checked + .checkmark::before {
  display: block;
}
.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-right: 16px;
}


.text-gray-primary {
    color: #636363;
}
