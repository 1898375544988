.SessionContainer {
  max-width: 670px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 13px;
  padding-top: 40px;
  /* min-height: 620px; */
  margin-bottom: 10px;
}

.errorStyle {
  color: red;
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 0 !important;
}

.SessionContain {
  max-width: 460px;
  margin: auto;
}
.SessionTitle{
  padding-top: 20px;
}
.SessionTitle h3 {
  text-align: center;
  color: white;
}

.SessionTitle p {
  text-align: center;
  color: #a0a0a0;
  font-size: 14px;
  line-height: 25px;
}

.SessionType h4 {
  font-size: 17px;
  color: #a0a0a0;
}

.SessionType {
  padding-top: 35px;
}

.GoalOptions {
  max-width: 500px;
  display: block;
  width: 100%;
  padding-top: 7px;
}

.GoalOptions input {
  display: none;
}

.GoalOptions span {
  padding: 5px 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
}

.TimeOptions {
  width: 100%;
  max-width: 500px;
  display: block;
  padding-top: 7px;
}

.TimeOptions input {
  display: none;
}

.TimeOptions span {
  padding: 5px 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
}

.TimeSession {
  padding-top: 20px;
}

.NarrationOptions input {
  display: none;
}

.NarrationOptions span {
  padding: 5px 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
}

.NarrationSession {
  padding-top: 20px;
}

.NarrationOptions {
  padding-top: 7px;
}

.ThemeOptions {
  max-width: 500px;
  width: 100%;
  display: block;
  padding-top: 7px;
}

.ThemeOptions input {
  display: none;
}

.ThemeOptions span {
  padding: 5px 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
}

.ThemeSession {
  padding-top: 20px;
}

.SessionBtn button {
  width: 100%;
  background-color: #29aae3 !important;
  border-radius: 7px;
  height: 44px;
  border: none;
  margin-bottom: 20px;
  margin-top: 13px;
}

.SessionBtn button span {
  font-size: 18px !important; 
  font-weight: 500 !important;
  color: #000000 !important;
}

.SessionBtn a {
  text-decoration: none;
}

.SessionBtn .btn:focus {
  box-shadow: none;
}

.SessionBtn .btn-primary:active:focus {
  box-shadow: none;
}

.SessionContain label input:checked+span {
  border-color: #29aae3;
}

.hideDiv {
  display: none !important;
}

/* ----Responsive Area---- */

@media screen and (max-width: 510px) {
  .SessionType {
    margin: 0px 20px;
  }
}

.narrationcolor {
  color: white;
}

.defaultdata {
  padding: 5px 20px;
  border-radius: 4px;
  border: 2px solid transparent;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
}

.session_tutorial {
  border-radius: 8px !important;
  margin-left: 20px !important;
  margin-top: 14px;
  display: initial !important;
  max-width: 150px;
  width: 100%;
  background: #29AAE3 !important;
  height: 44px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font: normal normal 500 18px/19px Rubik !important;
  color: #000000 !important;
  opacity: 0 !important;
}

/* new work */

 carousel-item{
  width: 100% !important;
 }

 /* .active{
width: 100% !important;
 } */

 .carousel-item-next, .carousel-item-prev, .carousel-item.active {
  width: 100% !important;
 }